import React, { useState, useEffect } from "react";
import axios from 'axios'

import PackagesPrices from "./PackagesPrices";
import PackagesItems from "./PackagesItems";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './App.css';
import { CardChecklist, EyeFill, EyeSlashFill, PencilFill, PlusCircle, TagsFill, Wallet2, X } from "react-bootstrap-icons";

const PACKAGES_API = 'http://192.169.176.97/rest/api/paquetes/packages'
const ITEMS_API = 'http://192.169.176.97/rest/api/paquetes/items'
const DAYS_API = 'http://192.169.176.97/rest/api/paquetes/days'
const PEOPLE_API = 'http://192.169.176.97/rest/api/paquetes/people'
const UPDATE_PACKAGE_API = 'http://192.169.176.97/rest/api/paquetes/addpackage'
const UPDATE_DAY_API = 'http://192.169.176.97/rest/api/paquetes/updateDay'
const UPDATE_ITEM_API = 'http://192.169.176.97/rest/api/paquetes/additem'
const UPDATE_PEOPLE_API = 'http://192.169.176.97/rest/api/paquetes/updatePeople'

const UPDATE_STATUS_PRICES_API = 'http://192.169.176.97/rest/api/paquetes/updateStatusPackagePrice/'

const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hour: "numeric", minute: "numeric", hour12: false, timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

const dias = { "week": 'Entre semana', "friday": 'Viernes o día festivo', "weekend": 'Fin de semana' }

function Packages({ setGlobalLoading }) {
  const [ packages, setPackages ] = useState([])
  const [ items, setItems ] = useState([])
  const [ days, setDays ] = useState([])
  const [ peopleList, setPeopleList ] = useState([])
  const [ show, setShow ] = useState(false)
  const [ showPeopleForm, setShowPeopleForm ] = useState(false)
  const [ showPackageItems, setShowPackageItems ] = useState(false)
  const [ showItems, setShowItems ] = useState(false)
  const [ showprices, setPrices ] = useState(false)
  const [ editPrice, setEditPrice ] = useState(null)
  const [ editItem, setEditItem ] = useState(null)

  const [ showDay, setShowDay ] = useState(false)
  const [ isNewDay, setIsNewDay ] = useState(false)
  const [ day, setDay ] = useState(null)
  const [ weekDays, setWeekDays ] = useState(null)
  const [ dayId, setDayId ] = useState(null)
  const [ dayName, setDayName ] = useState(null)

  const [ packageId, setPackageId ] = useState(null)
  const [ packageName, setPackageName ] = useState(null)
  const [ packegeNote, setPackegeNote ] = useState(null)

  const [ people, setPeople ] = useState([])
  const [ pack, setPack ] = useState(null)
  const [ state, setState ] = useState({})

  const [ showItemForm, setShowItemForm ] = useState(false)
  const [ itemId, setItemId ] = useState(null)
  const [ itemName, setItemName ] = useState(null)
  const [ itemPrice, setItemPrice ] = useState(0)

  const [ showPersonForm, setShowPersonForm ] = useState(false)
  const [ peopleId, setPeopleId ] = useState(null)
  const [ quantity, setQuantity ] = useState(0)

  useEffect(() => {
    getPackages()
    getItems()
    getDays()
    getPeople()
  }, []);

  const getPackages = () => {
    setGlobalLoading(true)

    axios({
      method: 'get',
      url: PACKAGES_API,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("packages", result.data)
      setPackages(result.data)

      if( pack != null ) {
        const res = result.data.find(({ packageId }) => packageId === pack.packageId);
        console.log("refresh result", res)
        setPeople( res.packagePeople )
      }
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const getItems = () => {
    axios({
      method: 'get',
      url: ITEMS_API,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("Items", result.data)
      setItems(result.data)
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const getDays = () => {
    axios({
      method: 'get',
      url: DAYS_API,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("Days", result.data)
      setDays(result.data)
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const getPeople = () => {
    axios({
      method: 'get',
      url: PEOPLE_API,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("PeopleList", result.data)
      setPeopleList(result.data)
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const showPackageForm = (item) => { 
    if(item) {
      setPackageId(item.packageId)
      setPackageName(item.packageName)
      setPackegeNote(item.packegeNote)
    } else {
      setPackageId(null)
      setPackageName(null)
      setPackegeNote(null)
    }
    
    setShow(true)
  }

  const showPackagePeopleForm = (item) => {
    console.log("item", item)
    setPack(item)
    setPeople(item.packagePeople)
    setShowPeopleForm(true)
  }

  const showPackageItemsForm = (item) => {
    console.log("item", item)
    setPack(item)
    setPeople(item.packagePeople)
    setShowPackageItems(true)
  }

  const showItemsForm = (item) => {    
    setShow(true)
  }

  const updateItem = (e) => {
    e.preventDefault();
    setGlobalLoading(true)

    let date = new Date().getTime()

    let json = {
      "packageId": packageId,
      "packageName": packageName,
      "packegeNote": packegeNote
    }

    console.log("update charge", json)

    axios({
      method: 'post',
      url: UPDATE_PACKAGE_API,
      headers: { 'content-type': 'application/json' },
      data: json
    })
    .then(result => {
      console.log("result update", result.data)
      setShow(false)
      getPackages()
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setShow(false)
      setGlobalLoading(false)
    })
  }

  const showDayForm = (item) => {    
    if(item){ 
      setIsNewDay( false )
      setDay( item )
      setDayId( item.day )
      setDayName( item.name )
      setWeekDays( item.weekDays )

      if(item.weekDays){
        var activeItems = {}
        item.weekDays.split(",").map( item => {
          activeItems['d' + item] = true
        })
        setState(activeItems);
        console.log('activeItems', activeItems)
      }

    } else {
      setIsNewDay( true )
      setDay( null )
      setDayId( null )
      setDayName( null )
    }

    setShowDay( true )
  }

  const updateDay = (e) => {
    e.preventDefault();
    setGlobalLoading(true)

    var weekDaysTemp = ""

    for (var key in state){
      var value = state[key];
      weekDaysTemp += key.replace("d", "") + ",";
    }

    let json = {
      "day": dayId,
      "name": dayName,
      "weekDays": weekDaysTemp ? weekDaysTemp.slice(0, -1) : null
    }

    console.log("update charge", json)

    axios({
      method: 'post',
      url: UPDATE_DAY_API,
      headers: { 'content-type': 'application/json' },
      data: json
    })
    .then(result => {
      console.log("result update", result.data)
      setShowDay(false)
      getPackages()
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setShow(false)
      setGlobalLoading(false)
    })
  }

  const editPeople = ( item ) => {
    setPrices( true )
    setEditPrice( item )
  }

  const closeEditPeople = () => {
    setPrices( false )
    setEditPrice( null )
    getPackages()
  }

  const editItems = ( item ) => {
    console.log('setEditItem', item)
    setShowItems( true )
    setEditItem( item )
  }

  const closeEditItems = () => {
    setShowItems( false )
    setEditItem( null )
    getPackages()
  }

  const getItemEntity = (itemId) => {
    var result = items.filter(obj => {
      return obj.itemId === itemId
    })

    return result[0].itemName
  }

  const onExtraChange = (e) => {
    const { name, value, checked, type } = e.target;

    console.log("onExtraChange", name, type, value)
    setState({
      ...state,
      [name]: checked
    });
  }

  const showItemFormFunction = ( item ) => {
    if(item){ 
      setItemId( item.itemId )
      setItemName( item.itemName )
      setItemPrice( item.itemPrice )
    } else {
      setItemId( null )
      setItemName( null )
      setItemPrice( null )
    }

    setShowItemForm( true )
  }

  const updateItemForm = (e) => {
    setGlobalLoading(true)
    e.preventDefault();

    let json = {
      "itemId": itemId,
      "itemName": itemName,
      "itemPrice": itemPrice
    }

    console.log("update item", json)

    axios({
      method: 'post',
      url: UPDATE_ITEM_API,
      headers: { 'content-type': 'application/json' },
      data: json
    })
    .then(result => {
      console.log("result update", result.data)
      setShowItemForm(false)
      getItems()
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setShowItemForm(false)
      setGlobalLoading(false)
    })
  }

  const showPeopleFormFunction = ( item ) => {
    if(item){ 
      setPeopleId( item.itemId )
      setQuantity( item.quantity )
    } else {
      setPeopleId( null )
      setQuantity( null )
    }

    setShowPersonForm( true )
  }

  const updatePeopleForm = (e) => {
    e.preventDefault();
    setGlobalLoading(true)

    let json = {
      "peopleId": peopleId,
      "quantity": quantity,
    }

    console.log("update people", json)

    axios({
      method: 'post',
      url: UPDATE_PEOPLE_API,
      headers: { 'content-type': 'application/json' },
      data: json
    })
    .then(result => {
      console.log("result update", result.data)
      setShowPersonForm(false)
      getPackages()
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setShowPersonForm(false)
      setGlobalLoading(false)
    })
  }

  const updateStatusPrices = (item) => {
    setGlobalLoading(true)

    axios({
      method: 'post',
      url: UPDATE_STATUS_PRICES_API + item.packagePeopleId,
      headers: { 'content-type': 'application/json' },
    })
    .then(result => {
      console.log("result update", result.data)
      setShow(false)
      getPackages()
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setShow(false)
      setGlobalLoading(false)
    })
  }

  return (
    <>

      <Modal show={ showPackageItems } size="lg">
        <Modal.Body className="modal-style">
          { showItems && 
            <PackagesItems closeEditItems={ closeEditItems } editItem={ editItem } setGlobalLoading={ setGlobalLoading } />
          }
          { !showItems && 
            <Container>
              <Row>
                <Col>
                  <div className="section-title">Elementos por paquete</div>
                </Col>
              </Row>
              <br/>
              <Row>
                { people.map( item => (
                  <Col md={ 6 } className="mb-4">
                    <Container className={ item.active ? 'main-component' : 'main-component-inactive' }>
                      <Row>
                        <Col md={ 6 }>
                          <div className="section-subtitle"> Personas </div>
                          <div className="section-title"> { item.people.quantity } </div>
                        </Col>
                        <Col md={ 6 }>
                          <div align="right">
                            { item.active && 
                              <>
                                <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                                  <PencilFill onClick={ () => editItems(item) } className="table-icon" />
                                </OverlayTrigger>&nbsp;
                              </>
                            }
                            <OverlayTrigger overlay={ item.active ? <Tooltip>Desacrivar</Tooltip> : <Tooltip>Activar</Tooltip> }>
                              { item.active ?  <EyeFill onClick={ () => updateStatusPrices(item) } className="table-icon" /> : <EyeSlashFill onClick={ () => updateStatusPrices(item) } className="table-icon" /> }
                            </OverlayTrigger>&nbsp;
                          </div>
                        </Col>
                      </Row>
                      <br/>
                      { !item.active && 
                        <div>Activa este elemento para editar y visualizar su contenido</div>
                      }
                      { item.active && 
                        <Row>
                          <Col>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Elemento</th>
                                  <th>Cantidad</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                { item.items.map( obj => (
                                  <tr>
                                    <td> { getItemEntity(obj.packageItemsId.itemId) } </td>
                                    <td align="right">{ obj.quantity == -1 ? 'Ilimitado' : obj.quantity }</td>
                                  </tr>
                                )) }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      }
                    </Container>
                  </Col>
                ))}
              </Row>
            </Container>
          }

          <br/>
          <div align="right">
            <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowPackageItems(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={ showPeopleForm } size="lg">
        <Modal.Body className="modal-style">
          { showprices && 
            <>
              <PackagesPrices closeEditPeople={ closeEditPeople } editPrice={ editPrice } />
            </>
          }
          { !showprices && 
            <Container>
              <Row>
                <Col>
                  <div className="section-title">Precios por número de personas y día</div>
                </Col>
              </Row>
              <br/>
              <Row>
                { people.map( item => (
                  <Col md={ 6 } className="mb-4">
                    <Container className={ item.active ? 'main-component' : 'main-component-inactive' }>
                      <Row>
                        <Col md={ 6 }>
                          <div className="section-subtitle"> Personas </div>
                          <div className="section-title"> { item.people.quantity } </div>
                        </Col>
                        <Col md={ 6 }>
                          <div align="right">
                            { item.active && 
                              <>
                                <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                                  <PencilFill onClick={ () => editPeople(item) } className="table-icon" />
                                </OverlayTrigger>&nbsp;
                              </>
                            }
                            <>
                              <OverlayTrigger overlay={ item.active ? <Tooltip>Desacrivar</Tooltip> : <Tooltip>Activar</Tooltip> }>
                                { item.active ?  <EyeFill onClick={ () => updateStatusPrices(item) } className="table-icon" /> : <EyeSlashFill onClick={ () => updateStatusPrices(item) } className="table-icon" /> }
                              </OverlayTrigger>&nbsp;
                            </>
                          </div>
                        </Col>
                      </Row>
                      <br/>
                      { !item.active && 
                        <div>Activa este elemento para editar y visualizar su contenido</div>
                      }
                      { item.active && 
                        <Row>
                          <Col>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>Día</th>
                                  <th>Precio</th>
                                </tr>
                              </thead>
                              <tbody>
                                { item.prices.map( obj => (
                                  <tr>
                                    <td> { obj.days.name } </td>
                                    <td >{ CURRENCY_FORMAT.format(obj.price) }</td>
                                  </tr>
                                )) }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      }
                    </Container>
                  </Col>
                ))}
              </Row>
            </Container>
          }

          <br/>
          <div align="right">
            <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowPeopleForm(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show}>
        <Modal.Body>
          <Form onSubmit={ updateItem }>
            <Container>
              <Row>
                <div>
                  <div className="section-title">Crea tu paquete</div>
                  <br/>

                  <Container>
                    <Row>
                      <Col md="12">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Nombre del Paquete">
                            <Form.Control required type="text" name="packageName" value={ packageName } placeholder="" onChange={ (e) => setPackageName(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Nota del Paquete">
                            <Form.Control as="textarea" type="text" name="packegeNote" value={ packegeNote } placeholder="" onChange={ (e) => setPackegeNote(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>

                  <div align="right">
                    <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShow(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
                    <button type="submit" className="grl-btn">{ 'ENVIAR' }</button>
                  </div>
                </div>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showDay}>
        <Modal.Body>
          <Form onSubmit={ updateDay }>
            <Container>
              <Row>
                <div>
                  <div className="section-title">Días de servicio</div>
                  <br/>

                  <Container>
                    <Row>
                      <Col md="12">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="ID del día">
                            <Form.Control required disabled={ !isNewDay } type="text" name="day" value={ dayId } placeholder="" onChange={ (e) => setDayId(e.target.value) }/>
                            <input type="hidden" name="day" value={ dayId } />
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Descripción">
                            <Form.Control type="text" name="name" value={ dayName } placeholder="" onChange={ (e) => setDayName(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>

                      <Col md="4">
                        <Form.Group className='mb-3'>
                          <Form.Check 
                            type="switch"
                            label='Domingo'
                            name="d0"
                            checked={ state[ 'd0' ] }
                            onChange={ (e) => onExtraChange(e) }
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group className='mb-3'>
                          <Form.Check 
                            type="switch"
                            label='Lunes'
                            name="d1"
                            checked={ state[ 'd1' ] }
                            onChange={ (e) => onExtraChange(e) }
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group className='mb-3'>
                          <Form.Check 
                            type="switch"
                            label='Martes'
                            name="d2"
                            checked={ state[ 'd2' ] }
                            onChange={ (e) => onExtraChange(e) }
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group className='mb-3'>
                          <Form.Check 
                            type="switch"
                            label='Miércoles'
                            name="d3"
                            checked={ state[ 'd3' ] }
                            onChange={ (e) => onExtraChange(e) }
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group className='mb-3'>
                          <Form.Check 
                            type="switch"
                            label='Jueves'
                            name="d4"
                            checked={ state[ 'd4' ] }
                            onChange={ (e) => onExtraChange(e) }
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group className='mb-3'>
                          <Form.Check 
                            type="switch"
                            label='Viernes'
                            name="d5"
                            checked={ state[ 'd5' ] }
                            onChange={ (e) => onExtraChange(e) }
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group className='mb-3'>
                          <Form.Check 
                            type="switch"
                            label='Sábado'
                            name="d6"
                            checked={ state[ 'd6' ] }
                            onChange={ (e) => onExtraChange(e) }
                          />
                        </Form.Group>
                      </Col>

                    </Row>
                  </Container>

                  <div align="right">
                    <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowDay(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
                    <button type="submit" className="grl-btn">{ 'Guardar' }</button>
                  </div>
                </div>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showItemForm}>
        <Modal.Body>
          <Form onSubmit={ updateItemForm }>
            <Container>
              <Row>
                <div>
                  <div className="section-title">Crea un Elemento</div>
                  <br/>

                  <Container>
                    <Row>
                      <Col md="12">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Nombre del Elemento">
                            <Form.Control required type="text" name="itemName" value={ itemName } placeholder="" onChange={ (e) => setItemName(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Precio">
                            <Form.Control required type="number" name="itemPrice" value={ itemPrice } placeholder="" onChange={ (e) => setItemPrice(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>

                  <div align="right">
                    <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowItemForm(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
                    <button type="submit" className="grl-btn">{ 'ENVIAR' }</button>
                  </div>
                </div>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={ showPersonForm }>
        <Modal.Body>
          <Form onSubmit={ updatePeopleForm }>
            <Container>
              <Row>
                <div>
                  <div className="section-title">Agrega número de personas</div>
                  <br/>

                  <Container>
                    <Row>
                      <Col md="12">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Número de personas">
                            <Form.Control required type="number" name="quantity" value={ quantity } placeholder="" onChange={ (e) => setQuantity(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>

                  <div align="right">
                    <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowPersonForm(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
                    <button type="submit" className="grl-btn">{ 'ENVIAR' }</button>
                  </div>
                </div>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>

      <Container>
        <Row>
          <Col>
            <Container>
              <Row>
                <Col md={ 12 }>
                  <Container className='main-component card-orange'>
                    <Row>
                      <Col md={ 5 } className="package-back"></Col>
                      <Col md={ 7 } className="card-box-left">
                        <div className="section-subtitle" style={{ fontSize: '40px', lineHeight: '50px' }}> Configuración de Paquetes </div>
                        <div className="section-subtitle subtitle-alt"> {  } </div>
                        <br/>
                        <div className="description-style"> Configura tus paquetes incluyendo días, precios, número de personas y elementos que incluirá tus paquetes. Tambien es posible administrar los elementos que puede inluir un paquete ya sea dentro del paquete mismo o bien, manejarlo como un extra. </div>
                        <br/>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
            <br/>
          </Col>
        </Row>
        <Row>
          <Col md={ 6 } className="col-md-6-custom">
            <Container className='main-component'>
              <Row>
                <Col md={ 9 }>
                  <div className="section-subtitle"> Paquetes </div>
                </Col>
                <Col md={ 3 } align="right">
                  <div className="single-btn" onClick={ () => showPackageForm() } > Nuevo </div>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Nombre del paquete</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { packages.map( item => (
                        <tr>
                          <td> { item.packageName } </td>
                          <td align="right"> 
                            <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                              <PencilFill onClick={ () => showPackageForm(item) } className="table-icon" />
                            </OverlayTrigger>&nbsp;
                            <OverlayTrigger overlay={<Tooltip>Precios</Tooltip>}>
                              <TagsFill onClick={ () => showPackagePeopleForm(item) } className="table-icon" />
                            </OverlayTrigger>&nbsp;
                            <OverlayTrigger overlay={<Tooltip>Inluido en paquete</Tooltip>}>
                              <CardChecklist onClick={ () => showPackageItemsForm(item) } className="table-icon" />
                            </OverlayTrigger>
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={ 6 } className="col-md-6-custom">
            <Container className='main-component container-max-height'>
              <Row>
                <Col md={ 9 }>
                  <div className="section-subtitle"> Elementos </div>
                </Col>
                <Col md={ 3 } align="right">
                  <div className="single-btn" onClick={ () => showItemFormFunction() } > Nuevo </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <br/>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Elemento</th>
                        <th>Precio</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { items.map( item => (
                        <tr>
                          <td> { item.itemName } </td>
                          <td> { CURRENCY_FORMAT.format(item.itemPrice) } </td>
                          <td> 
                            <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                              <PencilFill onClick={ () => showItemFormFunction(item) } className="table-icon" />
                            </OverlayTrigger>
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={ 6 } className="col-md-6-custom">
            <Container className='main-component'>
              <Row>
                <Col md={ 9 }>
                  <div className="section-subtitle"> Días de servicio </div>
                </Col>
                <Col md={ 3 } align="right">
                  <div className="single-btn" onClick={ () => showDayForm() } > Nuevo </div>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { days.map( item => (
                        <tr>
                          <td> { item.day } </td>
                          <td> { item.name } </td>
                          <td align="right"> 
                            <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                              <PencilFill onClick={ () => showDayForm(item) } className="table-icon" />
                            </OverlayTrigger>&nbsp;
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={ 6 } className="col-md-6-custom">
            <Container className='main-component'>
              <Row>
                <Col md={ 9 }>
                  <div className="section-subtitle"> Número de Personas </div>
                </Col>
                <Col md={ 3 } align="right">
                  <div className="single-btn" onClick={ () => showPeopleFormFunction() } > Nuevo </div>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Número de personas</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { peopleList.map( item => (
                        <tr>
                          <td> { item.quantity } </td>
                          <td align="right"> 
                            <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                              <PencilFill onClick={ () => showPeopleFormFunction(item) } className="table-icon" />
                            </OverlayTrigger>&nbsp;
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default Packages;
