import React from "react";

const GlobalContext = React.createContext({
  monthIndex: 0,
  setMonthIndex: (index) => {},
  smallCalendarMonth: 0,
  setSmallCalendarMonth: (index) => {},
  daySelected: null,
  setDaySelected: (day) => {},
  showEventModal: false,
  setShowEventModal: () => {},
  showEventForm: false,
  setShowEventForm: () => {},
  dispatchCalEvent: ({ type, payload }) => {},
  savedEvents: [],
  selectedEvent: null,
  setSelectedEvent: () => {},
  setLabels: () => {},
  labels: [],
  updateLabel: () => {},
  filteredEvents: [],
  showPayments: false,
  setShowPayments: () => {},
  showContract: false,
  setShowContract: () => {},
  showPaymentForm: false,
  setShowPaymentForm: () => {},
  currentTotalEvents: 0,
  setCurrentTotalEvents: () => {},
  totalCharge: 0,
  setTotalCharge: () => {},
  countCharge: 0,
  setCountCharge: () => {},
  countSales: 0,
  setCountSales: () => {},
  dayEventsMobile: [],
  setDayEventsMobile: () => {}
});

export default GlobalContext;
