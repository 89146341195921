import React, { useState, useEffect, useContext } from "react";
import axios from 'axios'
import AutoSuggest from 'react-autosuggest-ui'
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import { isMobile } from "react-device-detect";

import PaymentForm from "./PaymentForm";
import EventForm from "./EventForm";
import Print from "./Print";
import Contract from "./Contract";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import { getMonth } from "./util";
import CalendarHeader from "./components/CalendarHeader";
import Sidebar from "./components/Sidebar";
import Month from "./components/Month";
import GlobalContext from "./context/GlobalContext";
import EventModal from "./components/EventModal";

import './App.css';
import { Check, Wallet2, X, PrinterFill, InfoCircleFill } from "react-bootstrap-icons";

const EVENTS_API = 'http://192.169.176.97/rest/api/events/nextevents'
const PAYMENTS_API = 'http://192.169.176.97/rest/api/payments/balance/'
const CANCEL_PAYMENTS_API = 'http://192.169.176.97/rest/api/payments/cancelPayment/'
const DAYS_API = 'http://192.169.176.97/rest/api/paquetes/days'
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hour: "numeric", minute: "numeric", hour12: false, timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })
const DATE_FORMAT_SHORT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })
const SIMPLE_DATE_FORMAT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

const paymentTypes = {
  "1": "Efectivo",
  "2": "Tarjeta",
  "3": "Transferencia",
}

const paymentStatus = {
  "1": "Pendiente",
  "2": "Pagado",
  "3": "Cancelado",
  "4": "Reembolsado",
  "5": "Adelanto",
  "6": "Ingreso adicional al evento",
}

const HOURS = [
  { value: '10', label: '10:00 a 15:00 hrs' },
  { value: '16', label: '16:00 a 21:00 hrs' },
  { value: '12', label: '12:00 a 17:00 hrs' },
  { value: '13', label: '13:00 a 18:00 hrs' },
  { value: '14', label: '14:00 a 19:00 hrs' },
  { value: '15', label: '15:00 a 20:00 hrs' },
]

function Events({ setGlobalLoading }) {
  const [ eventList, setEventList ] = useState([])
  const [ currentEvents, setCurrentEvents ] = useState([])
  const [ eventItem, setEventItem ] = useState({ "eventName": "", "cost": 0, "eventDate": new Date() })
  const [ balance, setBalance ] = useState({ "amount": 0, "payments": []  })
  const [ suggestData, setSuggestData ] = useState([])
  const [ days, setDays ] = useState([])
  const [ showPrintForm, setShowPrintForm ] = useState(false)
  const [ paymentSelected, setPaymentSelected ] = useState([])
  const [ hours, setHours ] = useState('')
  const [ totalPayment, setTotalPayment ] = useState(0)
  const [ balancePrint, setBalancePrint ] = useState(0)
  const [ totalEvents, setTotalEvents ] = useState(0)
  const [ currentDate, setCurrentDate ] = useState(null)
  const [ sales, setSales ] = useState(0)

  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex,
    showEventModal,
    setShowEventModal,
    showEventForm,
    setShowEventForm,
    selectedEvent,
    setSelectedEvent,
    showPayments,
    setShowPayments,
    showContract,
    setShowContract,
    showPaymentForm,
    setShowPaymentForm,
    currentTotalEvents,
    setCurrentTotalEvents,
    countSales,
    setCountSales,
    dispatchCalEvent } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));

    var current = []
    var eventCount = 0
    var salesCount = 0

    console.log("eventList", eventList)
    eventList.map( item => {
      var date = new Date( item.eventDate )
      var now = new Date( dayjs().year(), monthIndex )
      var salesDate = item.saleDate ? new Date( item.saleDate ) : null

      if( date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear() ) { 
        eventCount ++
        salesCount += item.cost
        //current.push( item )
      }

      /*if( salesDate && salesDate.getMonth() == monthIndex && salesDate.getFullYear() == dayjs().year() )
        salesCount ++*/
    })

    setCurrentDate( dayjs( new Date( dayjs().year(), monthIndex ) ).locale("es").format( "MMMM YYYY" ) )
    //setCurrentEvents( current )
    setTotalEvents( eventCount )
    setSales( salesCount )
  }, [monthIndex]);

  useEffect(() => {
    getBalance()
  }, [showPayments]);

  useEffect(() => {
    setGlobalLoading(true)
    //getEvents()
    getDays()
  }, []);

  useEffect(() => {
    if( !showEventForm ) getEvents()
  }, [showEventForm])

  const getEvents = () => {
    axios({
      method: 'get',
      url: EVENTS_API,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      //console.log("events", result.data)
      var suggest = []
      var current = []
      var eventCount = 0
      var salesCount = 0
      setEventList( result.data )
      dispatchCalEvent({ type: "delete", payload: "" });

      result.data.map( item => {
        var date = new Date( item.eventDate )
        var now = new Date()
        var salesDate = item.saleDate ? new Date( item.saleDate ) : null

        const diffTime = (new Date( date.getFullYear(), date.getMonth(), date.getDate(), 0 ) - new Date( now.getFullYear(), now.getMonth(), now.getDate(), 0 ));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        if( date < now && item.balance > item.cost ) item.label = "gray"
        else if( item.balance < item.cost ) item.label = "red"
        else if( date < now ) item.label = "blue"
        else item.label = "indigo"
        //else if( date < now && item.balance < item.cost ) item.label = "red"

        suggest.push({ name: item.eventName ? item.eventName : '', value: item.clientName ? item.clientName : item.eventName })
        dispatchCalEvent({ type: "push", payload: item });

        if( date.getMonth() == monthIndex && date.getFullYear() == dayjs().year() ) {
          eventCount ++
          salesCount += item.cost
          //if( date.getDate() >= now.getDate() ) current.push( item )
        }

        if( parseInt(diffDays) > 0 && parseInt(diffDays) <= 15 ) {
          current.push( item )
        }

        /*if( salesDate && salesDate.getMonth() == monthIndex && salesDate.getFullYear() == dayjs().year() )
          salesCount ++*/
      })

      setCurrentDate( dayjs(new Date( dayjs().year(), monthIndex )).locale("es").format( "MMMM YYYY" ) )
      setCurrentEvents( current )
      setTotalEvents( eventCount )
      setSales( salesCount )
      
      if( currentTotalEvents == null ) setCurrentTotalEvents( eventCount )
      if( countSales == null ) setCountSales( salesCount )

      setSuggestData(suggest)
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const getBalance = () => {
    if(selectedEvent != null){
      setGlobalLoading(true)
      axios({
        method: 'get',
        url: PAYMENTS_API + "/" + selectedEvent.eventId,
        //headers: { 'content-type': 'application/json' },
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(result => {
        //console.log("balance", result.data)
        var total = 0;

        setBalance(result.data)
        setEventItem(selectedEvent)
        setGlobalLoading(false)

        result.data.payments.map( item => {
          if(item.paymentStatusId == 2)
            total += item.paymentAmount
        })

        setTotalPayment(total)

        let date  = new Date(selectedEvent.eventDate)
        let hourP = date.getHours()
        setHours( HOURS.find( (e) => e.value == hourP ) )
      })
      .catch(error => {
        console.log("error", error.message)
      })
    }
  }

  const getPaymentForm = () => {
    setShowPayments(false)
    setShowPaymentForm(true)
  }

  const handleOnChange = (selected) => {
    console.log("selected on chance", selected)
    eventList.map( (item, i) => {
      if(item.eventName == selected.name) {
        console.log("found")
        setSelectedEvent(item)
        setShowEventModal(true)
      }
    })
  }

  const getDays = () => {
    axios({
      method: 'get',
      url: DAYS_API,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      //console.log("Days", result.data)
      setDays(result.data)
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const printTicket = (element) => {
    var bal = 0

    balance.payments.map( item => {
      if( item.paymentStatus.paymentStatusId != 3 &&  item.paymentId < element.paymentId ) bal += item.paymentAmount
    } )

    setBalancePrint( bal )
    setShowPrintForm(true)
    setPaymentSelected(element)
  }

  const cancelPayment = ( paymentId ) => {
    axios({
      method: 'get',
      url: CANCEL_PAYMENTS_API + paymentId,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      //console.log("Balance", result.data)
      getBalance()
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  function MoneyFormat(labelValue) {
    // Nine Zeroes for Billions
    var result = Math.abs(Number(labelValue)) >= 1.0e+9

       ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
       // Six Zeroes for Millions 
       : Math.abs(Number(labelValue)) >= 1.0e+6

       ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
       // Three Zeroes for Thousands
       : Math.abs(Number(labelValue)) >= 1.0e+3

       ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

       : Math.abs(Number(labelValue));

    return '$' + result;

  }

  return (
    <>

      <Modal show={showPaymentForm}>
        <Modal.Body>
          <PaymentForm eventItem={ eventItem } />
        </Modal.Body>
      </Modal>

      <Modal size={ 'xl' } show={showPrintForm}>
        <Modal.Body>
          <Print payment={ paymentSelected } event={ eventItem } balancePrint={ balancePrint }/>
          <br/>
          <div align="right">
            <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowPrintForm(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showPayments} size="xl">
        <Modal.Body>
          <Container>
            <Row>
              <div>
                <br/>
                <div className="section-path"> Pagos </div>
                <div className="section-title">{ eventItem.eventName } </div>
                <div><span><b>Fecha del Evento:</b></span>&nbsp;<span>{ DATE_FORMAT_SHORT.format((new Date(eventItem.eventDate)).getTime()) }</span></div>
                <div><span><b>Hora del Evento:</b></span>&nbsp;<span>{ hours.label }</span></div>
                <br/>
                <Container>
                  <Row>
                    <Col md={ 4 }>
                      <div className="small-title">Total</div>
                      <div className="medium-title">{ CURRENCY_FORMAT.format( eventItem.cost ) }</div>
                    </Col>

                    <Col md={ 4 }>
                      <div className="small-title">Abonado</div>
                      <div className="medium-title">{ CURRENCY_FORMAT.format( totalPayment ) }</div>
                    </Col>

                    <Col md={ 4 }>
                      <div className="small-title">Balance</div>
                      <div className="medium-title">{ CURRENCY_FORMAT.format( balance.amount ) }</div>
                    </Col>
                  </Row>
                </Container>
                <br/>
              </div>
            </Row>
            <Row>
              <Col md={ 9 }>
                <div className="section-subtitle"> Lista de pagos </div>
              </Col>
              <Col md={ 3 } align="right">
                <div className="single-btn" onClick={ () => getPaymentForm() } > Nuevo </div>
              </Col>
            </Row>
            <Row>
              <div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Monto</th>
                      <th>Num. de pago</th>
                      <th>Tipo de Pago</th>
                      <th>Estatus</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { balance.payments.map( item => (
                      <tr>
                        <td> { SIMPLE_DATE_FORMAT.format((new Date(item.dateApplication)).getTime()) } </td>
                        <td> { CURRENCY_FORMAT.format(item.paymentAmount) } </td>
                        <td> { item.payNumber } </td>
                        <td> { item.paymentType.name } </td>
                        <td> { item.paymentStatus.description } </td>
                        <td> 
                          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{ item.note }</Tooltip>}>
                            {({ ref, ...triggerHandler }) => (
                              <Button variant="light" {...triggerHandler} className="btn-simple d-inline-flex align-items-center" >
                                <InfoCircleFill ref={ref} className="table-icon"/>
                              </Button>
                            )}
                          </OverlayTrigger>&nbsp;&nbsp;
                          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Imprimir Recibo</Tooltip>}>
                            <PrinterFill onClick={ () => printTicket(item) } className="table-icon" />
                          </OverlayTrigger>&nbsp;&nbsp;
                          { item.paymentStatus.paymentStatusId != 3 && 
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Cancelar Recibo</Tooltip>}>
                              <X className="table-icon" onClick={ () => cancelPayment( item.paymentId ) }/>
                            </OverlayTrigger>
                          }
                        </td>
                      </tr>
                    )) }
                  </tbody>
                </Table>
              </div>
            </Row>
          </Container>
          
          <br/>
          <div align="right">
            <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowPayments(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
          </div>
          
        </Modal.Body>
      </Modal>

      <Modal show={showContract} size={ 'lg' } onHide={ () => setShowContract( false ) } >
        <Modal.Body className="event-container">
          <Contract setGlobalLoading={ setGlobalLoading } />
        </Modal.Body>
      </Modal>
      
      <Modal show={showEventForm} size="lg">
        <Modal.Body className="event-container">
          <EventForm days={ days } setGlobalLoading={ setGlobalLoading }/>
        </Modal.Body>
      </Modal>

      {showEventModal && <EventModal getEvents={ getEvents } />}

      <Container style={ isMobile ? { padding: 0 } : {} }>
        <Row>
          <Col md={ 8 } style={ isMobile ? { padding: 0 } : {} }>
            <div className='main-component'>
              <div className="h-screen flex flex-col">
                <CalendarHeader />
                <div className="flex flex-1">
                  {/* <Sidebar /> */}
                  <Month month={currenMonth} />
                </div>
              </div>
            </div>
          </Col>
          <Col md={ 4 }>
            { isMobile && 
              <br/>
            }
            <div className='main-component card-violet-alt'>
              <div className="section-subtitle" style={{ color: '#fff', fontSize: '40px', lineHeight: '50px' }}> Resumen </div>
              <div className="section-subtitle subtitle-alt" style={{ color: '#fff' }}> { currentDate } </div>
              
              <br/>
              <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Total de eventos</div>
              <div className="medium-title" style={{ color: '#fff' }}>{ totalEvents }</div>
              <br/>
              <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Venta del mes</div>
              <div className="medium-title" style={{ color: '#fff' }}>{ MoneyFormat(sales) /*+ '' + CURRENCY_FORMAT.format( sales )*/ }</div>

            </div>

            <div className="search-container" align="right">
              <AutoSuggest
                data={suggestData}
                onChange={handleOnChange}
                placeholder="Buscar por nombre del evento"
              />
            </div>

            <div className='main-component'>
              <Table responsive>
                <thead>
                  <th>Próximos eventos</th>
                </thead>
                <tbody>
                  { currentEvents.map( item => (
                    <tr>
                      <td className='item-event' style={{ cursor: 'pointer' }} onClick={ () => { if( !isMobile ) { handleOnChange( {name: item.eventName, value: item.clientName } ) } } }> 
                        <div><span className={ item.label == 'red' ? `item-event-${item.label} item-event-color sub-date` : "sub-date" }>{ DATE_FORMAT.format((new Date( item.eventDate )).getTime()) } </span></div>
                        <div> { item.eventName } </div>
                      </td>
                    </tr>
                  )) }
                </tbody>
              </Table>
            </div>

          </Col>
        </Row>
      </Container>
      


      {/* <Container className='main-component'>
        <Row>
          <Col md={ 9 }>
            <div className="section-subtitle"> Lista de eventos </div>
          </Col>
          <Col md={ 3 } align="right">
            <div className="single-btn" onClick={ () => showEventForm() } > Nuevo </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>Evento</th>
                  <th>Contacto</th>
                  <th>Fecha del evento</th>
                  <th>Anticipo</th>
                  <th>Paquete</th>
                  <th>Costo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { eventList.map( item => (
                  <tr>
                    <td> 
                      <div className="item-link" onClick={ () => showEventForm(item) }> 
                        {  item.statusEventId == 1 && <Check className="status-ok-icon" /> }
                        {  item.statusEventId == 2 && <X className="status-cancel-icon" /> }
                        &nbsp;&nbsp;{ item.eventName } 
                      </div>
                    </td>
                    <td> { item.nameContactEvent } </td>
                    <td> { DATE_FORMAT.format((new Date(item.eventDate)).getTime()) } </td>
                    <td> { CURRENCY_FORMAT.format(item.advancePay) } </td>
                    <td> { (item.packageEvent).toUpperCase() } </td>
                    <td> { CURRENCY_FORMAT.format(item.cost) } </td>
                    <td> 
                      <Wallet2 onClick={ () => getBalance(item) } className="table-icon" /> &nbsp;
                      {  item.statusEventId != 2 && 
                        <> <X onClick={ () => cancelEvent(item.eventId) } className="table-icon" /> </>
                      }
                    </td>
                  </tr>
                )) }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container> */}

    </>
  );
}

export default Events;
