import React, {
  useState,
  useEffect,
  useReducer,
  useMemo,
} from "react";
import GlobalContext from "./GlobalContext";
import dayjs from "dayjs";

function savedEventsReducer(state, { type, payload }) {
  switch (type) {
    case "push":
      return [...state, payload];
    case "update":
      return state.map((evt) =>
        evt.id === payload.id ? payload : evt
      );
    case "delete":
      return state.filter((evt) => evt.id !== payload.id);
    default:
      throw new Error();
  }
}
function initEvents() {
  //const storageEvents = localStorage.getItem("savedEvents");
  //const parsedEvents = storageEvents ? JSON.parse(storageEvents) : [];
  //return parsedEvents;
  return []
}

export default function ContextWrapper(props) {
  const [ monthIndex, setMonthIndex] = useState(dayjs().month());
  const [ smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [ daySelected, setDaySelected] = useState(dayjs());
  const [ showEventModal, setShowEventModal] = useState(false);
  const [ showEventForm, setShowEventForm] = useState(false);
  const [ showPayments, setShowPayments ] = useState(false)
  const [ showContract, setShowContract ] = useState(false)
  const [ showPaymentForm, setShowPaymentForm ] = useState(false)
  const [ selectedEvent, setSelectedEvent ] = useState(null);
  const [ labels, setLabels] = useState([]);
  const [ dateSelected, setDateSelected ] = useState(dayjs())
  const [ currentTotalEvents, setCurrentTotalEvents ] = useState(null)
  const [ totalCharge, setTotalCharge ] = useState(0)
  const [ countCharge, setCountCharge ] = useState(0)
  const [ countSales, setCountSales ] = useState(null)
  const [ dayEventsMobile, setDayEventsMobile ] = useState([])

  const [ savedEvents, dispatchCalEvent] = useReducer(
    savedEventsReducer,
    [],
    initEvents
  );
  const filteredEvents = useMemo(() => {
    return savedEvents.filter((evt) =>
      labels
        .filter((lbl) => lbl.checked)
        .map((lbl) => lbl.label)
        .includes(evt.label)
    );
  }, [savedEvents, labels]);

  useEffect(() => {
    localStorage.setItem("savedEvents", JSON.stringify(savedEvents));
  }, [savedEvents]);

  useEffect(() => {
    //console.log("savedEvents mapping", savedEvents)
    setLabels((prevLabels) => {
      return [...new Set(savedEvents.map((evt) => evt.label))].map(
        (label) => {
          const currentLabel = prevLabels.find(
            (lbl) => lbl.label === label
          );
          return {
            label,
            checked: currentLabel ? currentLabel.checked : true,
          };
        }
      );
    });
  }, [savedEvents]);

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setMonthIndex(smallCalendarMonth);
    }
  }, [smallCalendarMonth]);

  useEffect(() => {
    if (!showEventModal) {
      setSelectedEvent(null);
    }
  }, [showEventModal]);

  function updateLabel(label) {
    setLabels(
      labels.map((lbl) => (lbl.label === label.label ? label : lbl))
    );
  }

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        showEventModal,
        setShowEventModal,
        showEventForm,
        setShowEventForm,
        dispatchCalEvent,
        selectedEvent,
        setSelectedEvent,
        savedEvents,
        setLabels,
        labels,
        updateLabel,
        filteredEvents,
        showPayments,
        setShowPayments,
        showContract,
        setShowContract,
        showPaymentForm,
        setShowPaymentForm,
        dateSelected,
        setDateSelected,
        currentTotalEvents,
        setCurrentTotalEvents,
        totalCharge,
        setTotalCharge,
        countCharge,
        setCountCharge,
        countSales,
        setCountSales,
        dayEventsMobile,
        setDayEventsMobile
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
