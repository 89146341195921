import React, { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from './img/logo_b.png'
import './App.css';

function Menu({ handleSelectSection, section }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container>
        <Row>
          <Col md={ 12 }>
            
            { !isMobile && 
              <>
                <div align="center">
                  <img src={ logo } className="logo" />
                </div>
                <hr/>
              </>
            }

            <div>
              {/* <div className="menu-bar" onClick={ () => handleSelectSection( 'events' ) }> <BalloonFill className="menu-icon" />&nbsp;&nbsp; Eventos</div>*/ }
              <div className="category-menu">Operacion</div>
              <div className="menu-bar" onClick={ () => handleSelectSection( 'events' ) }>
                <span className={ section == 'events' ? "material-icons-outlined menu-icon menu-icon-active" : "material-icons-outlined menu-icon" }>
                  cake
                </span>&nbsp;&nbsp;
                <span className={ section == 'events' ? "menu-item menu-item-active" : "menu-item" }>Eventos</span>
              </div>
              <div className="menu-bar" onClick={ () => handleSelectSection( 'payments' ) }>
                <span className={ section == 'payments' ? "material-icons-outlined menu-icon menu-icon-active" : "material-icons-outlined menu-icon" }>
                  payments
                </span>&nbsp;&nbsp;
                <span className={ section == 'payments' ? "menu-item menu-item-active" : "menu-item" }>Finanzas</span>
              </div>
              {/* <div className="menu-bar" onClick={ () => handleSelectSection( 'charges' ) }>
                <span className={ section == 'charges' ? "material-icons-outlined menu-icon menu-icon-active" : "material-icons-outlined menu-icon" }>
                  shopping_cart
                </span>&nbsp;&nbsp;
                <span className={ section == 'charges' ? "menu-item menu-item-active" : "menu-item" }>Gastos</span>
              </div> */}

              { !isMobile &&
                <>
                  <br/>
                  <div className="category-menu">Configuración</div>
                  <div className="menu-bar" onClick={ () => handleSelectSection( 'packages' ) }>
                    <span className={ section == 'packages' ? "material-icons-outlined menu-icon menu-icon-active" : "material-icons-outlined menu-icon" }>
                      inventory_2
                    </span>&nbsp;&nbsp;
                    <span className={ section == 'packages' ? "menu-item menu-item-active" : "menu-item" }>Paquetes</span>
                  </div>
                  <div className="menu-bar" onClick={ () => handleSelectSection( 'reports' ) }>
                    <span className={ section == 'reports' ? "material-icons-outlined menu-icon menu-icon-active" : "material-icons-outlined menu-icon" }>
                      stacked_bar_chart
                    </span>&nbsp;&nbsp;
                    <span className={ section == 'reports' ? "menu-item menu-item-active" : "menu-item" }>Reportes</span>
                  </div>
                </>
              }
            </div>
            
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Menu;
