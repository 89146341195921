import React, { useState, useEffect, useContext } from "react";
import GlobalContext from "./context/GlobalContext";
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'

import './App.css';
import { Wallet2, X } from "react-bootstrap-icons";

const EVENTS_API = 'http://192.169.176.97/rest/api/events/nextevents'
const UPDATE_API = 'http://192.169.176.97/rest/api/payments/addPayment'
const STATUS_API = 'http://192.169.176.97/rest/api/payments/getPaymentStatus'
const TYPES_API = 'http://192.169.176.97/rest/api/payments/getPaymentTypes'

function PaymentForm({ eventItem }) {
  const [ events, setEvents ] = useState([])
  const [ show, setShow ] = useState(false)
  const [ title, setTitle ] = useState("")
  const [ desc, setDesc ] = useState("")

  const [ cancelDate, setCancelDate ] = useState(null)
  const [ cancelReason, setCancelReason ] = useState(null)
  const [ eventId, setEventId ] = useState(null)
  const [ payNumber, setPayNumber ] = useState(null)
  const [ paymentAmount, setPaymentAmount ] = useState(null)
  const [ paymentDate, setPaymentDate ] = useState(null)
  const [ paymentId, setPaymentId ] = useState(null)
  const [ paymentStatusId, setPaymentStatusId ] = useState(null)
  const [ paymentType, setPaymentType ] = useState(null)
  const [ receiptId, setReceiptId ] = useState(null)
  const [ refundAmount, setRefundAmount ] = useState(null)
  const [ refundDate, setRefundDate ] = useState(null)
  const [ refundtransactionid, setRefundtransactionid ] = useState(null)
  const [ userCancel, setUserCancel ] = useState(null)
  const [ paymentNote, setPaymentNote ] = useState(null)
  const [ dateApplication, setDateApplication ] = useState(null)
  const [ description, setDescription ] = useState(null)

  const [ paymentStatus, setPaymentStatus ] = useState([])
  const [ paymentTypes, setPaymentTypes ] = useState([])

  const { showPaymentForm,
    setShowPaymentForm }
  = useContext(GlobalContext);

  useEffect(() => {
    if(!eventItem)
      getEvents()

    getStatus()
    getTypes()
    paymentForm()
  }, []);

  const getEvents = () => {
    axios({
      method: 'get',
      url: EVENTS_API,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("events on payment", result.data)
      setEvents( result.data )
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const getStatus = () => {
    axios({
      method: 'get',
      url: STATUS_API,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("events on payment", result.data)
      setPaymentStatus( result.data )
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const getTypes = () => {
    axios({
      method: 'get',
      url: TYPES_API,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("events on payment", result.data)
      setPaymentTypes( result.data )
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const paymentForm = () => {
    setCancelDate(null)
    setCancelReason(null)
    setEventId(eventItem ? eventItem.eventId : null)
    setPayNumber(null)
    setPaymentAmount(0)
    setPaymentDate(null)
    setPaymentId(null)
    setPaymentStatusId(2)
    setPaymentType(1)
    setReceiptId(null)
    setRefundAmount(null)
    setRefundDate(null)
    setRefundtransactionid(null)
    setPaymentNote(null)
    setDateApplication(null)
    setDescription(null)
  }

  const updateItem = (e) => {
    e.preventDefault();
    let date  = new Date()
    let applyStr = dateApplication.split('-')
    var applyDate = new Date( dateApplication );
    applyDate.setTime(applyDate.getTime() + applyDate.getTimezoneOffset() * 60 * 1000);

    let json = {
      "paymentId": paymentId,
      "eventId": eventId,
      "receiptId": receiptId, 
      "paymentTypeId": paymentType,
      "paymentDate": date,
      "paymentAmount": paymentAmount,
      "payNumber": payNumber,
      "paymentStatusId": paymentStatusId,
      "cancelDate": cancelDate,
      "cancelReason": cancelReason,
      "userCancel": userCancel,
      "refundDate": refundDate, 
      "refundtransactionid": refundtransactionid, 
      "refundAmount": refundAmount,
      "dateApplication": applyDate,
      "note": paymentNote,
      "description": description
    }

    console.log("update event", json)
    
    axios({
      method: 'post',
      url: UPDATE_API,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
      data: json
    })
    .then(result => {
      console.log("result update", result.data)
      setTitle("Pago realizado")
      setDesc("Gracias")
      setShowPaymentForm(false)
      setShow(true)
    })
    .catch(error => {
      console.log("error", error.message)
      setTitle("Pago Rechazado")
      setDesc("Favor de intentarlo más tarde")
      setShowPaymentForm(false)
      setShow(true)
    })

  }

  return (
    <>

      <Modal show={show}>
        <Modal.Body>
          <Container>
            <Row>
              <div>
                <br/>
                <div className="section-path"> Resultado </div>
                <div className="section-title">{ title } </div>
                <br/>
                <div>{ desc }</div>
                <br/>
              </div>
            </Row>
          </Container>

          <br/>
          <div align="right">
            <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShow(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
          </div>
        </Modal.Body>
      </Modal>


      <Container>
        <Row>
          <Form onSubmit={ updateItem }>
            <Container>
              <Row>
                <div>
                  <br/>
                  <div className="section-path"> Pagos </div>
                  <div className="section-title">Generar pago </div>
                  <br/>
                </div>
              </Row>
              <Row>
                <div>
                  <Container>
                    { events.length > 0 &&
                      <Row>
                        <Col md="12">
                          <Form.Group className='mb-3'>
                            <FloatingLabel controlId="floatingPassword" label="Evento">
                              <Form.Select name="eventId" value={ eventId } onChange={ (e) => setEventId(e.target.value) }>
                                <option value="">Selecciona tu evento</option>
                                { events.map(item => (
                                  <option value={ item.eventId }>{ item.eventName }</option>
                                ))}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                    }
                    <Row>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Monto">
                          <Form.Control required name="paymentAmount" value={ paymentAmount } placeholder="" onChange={ (e) => setPaymentAmount(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Tipo de pago">
                            <Form.Select name="paymentType" value={ paymentType } onChange={ (e) => setPaymentType(e.target.value) }>
                              <option value="">Selecciona una opción</option>
                              { paymentTypes.map( item => (
                                <option value={ item.paymentTypeId }>{ item.name }</option>
                              ))}
                            </Form.Select>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Número de referencia">
                            <Form.Control name="receiptId" value={ receiptId } placeholder="" onChange={ (e) => setReceiptId(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Fecha de aplicación">
                            <Form.Control required type="date" name="dateApplication" max={new Date().toISOString().split("T")[0]} value={ dateApplication } placeholder="" onChange={ (e) => setDateApplication(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel controlId="floatingPassword" label="Estatus del pago">
                            <Form.Select name="paymentStatusId" value={ paymentStatusId } onChange={ (e) => setPaymentStatusId(e.target.value) }>
                              <option value="">Selecciona una opción</option>
                              { paymentStatus.map( item => (
                                <option value={ item.paymentStatusId }>{ item.description }</option>
                              ))}
                            </Form.Select>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel controlId="floatingPassword" label="Número de pago">
                            <Form.Control type="number" name="payNumber" value={ payNumber } placeholder="" onChange={ (e) => setPayNumber(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group className="mb-3">
                          <FloatingLabel controlId="floatingPassword" label="Concepto de Pago">
                            <Form.Control as="textarea" name="description" value={ description } placeholder="" onChange={ (e) => setDescription(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group className="mb-3">
                          <FloatingLabel controlId="floatingPassword" label="Notas del Pago">
                            <Form.Control as="textarea" name="paymentNote" value={ paymentNote } placeholder="" onChange={ (e) => setPaymentNote(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>

                  <div align="right">
                    <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowPaymentForm(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
                    <button type="submit" className="grl-btn">{ 'ENVIAR' }</button>
                  </div>
                </div>
              </Row>
            </Container>
          </Form>
        </Row>
      </Container>

    </>
  );
}

export default PaymentForm;
