import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import avatar from './img/avatar.jpg'

import './App.css';

const menu = {
  "events": {
    path: "Operación /",
    title: "Eventos"
  },
  "payments": {
    path: "Operación /",
    title: "Finanzas"
  },
  "charges": {
    path: "Operación /",
    title: "Gastos"
  },
  "packages": {
    path: "Configuración /",
    title: "Paquetes"
  },
  "reports": {
    path: "Configuración /",
    title: "Reportes"
  },
}

function SectionTitle({ section, logOut }) {
  const [ path, setPath ] = useState("")
  const [ title, setTitle ] = useState("")
  const [ name, setName ] = useState(sessionStorage.getItem("splash_name"))

  useEffect(() => {
    let selection = menu[section];
    console.log("selection", selection)

    setPath( selection.path )
    setTitle( selection.title )
  }, [ section ]);

  return (
    <>
      
      <div>
        <Container>
          <Row className="align-items-center">
            <Col md={ 8 } xs={ 6 } align="left">
              <div className="section-path">
                { path }
              </div>
              <div className="section-title">
                { title }
              </div>
            </Col>

            <Col md={ 4 } xs={ 6 } align="right" className="align-items-center">
              <div className='section-bar' style={ { width: 'fit-content' } }>
                <Container>
                  <Row>
                    <Col>
                      <div className='' onClick={ () => logOut() }>
                        { name }
                      </div>
                      <div className='close-session' onClick={ () => logOut() }>
                        Cerrar sesión
                      </div>
                    </Col>
                    { !isMobile && 
                      <Col md={ 'auto' }>
                        <img src={ avatar } className="avatar" />
                      </Col>
                    }
                  </Row>
                </Container>
                
                {/* <span className={ "material-icons-outlined menu-icon-bar" }>
                  notifications
                </span>&nbsp;&nbsp;*/}
                
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
}

export default SectionTitle;
