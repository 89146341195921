import React, { useState, useEffect } from "react";
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'

import './App.css';
import { ArrowLeft } from "react-bootstrap-icons";

const UPDATE_PRICES_API = 'http://192.169.176.97/rest/api/paquetes/updatePackagePrice'
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hour: "numeric", minute: "numeric", hour12: false, timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

const dias = { "week": 'Entre semana', "friday": 'Viernes o día festivo', "weekend": 'Fin de semana' }

function PackagesPrices( { closeEditPeople, editPrice } ) {
  const [state, setState] = useState({});

  useEffect(() => {
    var tempPrices = {}

    editPrice.prices.map( item => {
      if( !tempPrices[item.day] ) {
        tempPrices[item.day] = item.price
      }
    })

    console.log('useeffect', tempPrices)
    setState( tempPrices )
  }, []);

  const updatePrices = (e) => {
    e.preventDefault();
    var form = document.getElementById('pricesForm');
		var formData = new FormData(form);

    axios.post(UPDATE_PRICES_API, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
    }).then(result => {
      console.log("result update", result.data)
      closeEditPeople()
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const handleOnPriceChange = (e) => {    
    const { name, value, checked, type } = e.target;
    const newVal = value;

    setState({
      ...state,
      [name]: newVal
    });
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={ 12 }>
            <Form id="pricesForm" onSubmit={ updatePrices }>
              <input type="hidden" name="packagePeopleId" value={ editPrice.packagePeopleId } />
              <Container>
                <Row>
                  <Col>
                    <div className="section-title">
                      <ArrowLeft onClick={ () => closeEditPeople() } className="table-icon" />&nbsp;&nbsp;
                      <span className="nav-title">Edita tus Precios</span>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col md={ 12 } className="mb-4">
                    <Container className='main-component'>
                      <Row>
                        <Col md={ 9 }>
                          <div className="section-subtitle"> Personas </div>
                          <div className="section-title"> { editPrice.people.quantity } </div>
                        </Col>
                      </Row>
                      <br/>
                      <Row>
                        <Col>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Día</th>
                                <th>Precio</th>
                              </tr>
                            </thead>
                            <tbody>
                              { editPrice.prices.map( obj => (
                                <tr>
                                  <td> { obj.days.name } </td>
                                  <td >
                                    <Form.Control required name={ obj.day } value={ state[ obj.day ] } onChange={ (e) => handleOnPriceChange(e) } />
                                  </td>
                                </tr>
                              )) }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
                <Row>
                  <div align="right">
                    <button type="submit" className="grl-btn"> Guardar </button>
                  </div>
                </Row>
              </Container>
            </Form>
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default PackagesPrices;
