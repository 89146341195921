import React, { useState, useEffect, useContext } from "react";
import axios from 'axios'
import { Chart } from "react-google-charts";
import { AreaChart, LineChart, Line, linearGradient, XAxis, YAxis, CartesianGrid, Area, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";

import PaymentForm from "./PaymentForm";
import Print from "./Print";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal'

import GlobalContext from "./context/GlobalContext";

import './App.css';
import { PrinterFill, Wallet2, X } from "react-bootstrap-icons";

const REPORT_EVENT_API = 'http://192.169.176.97/rest/api/reports/events'
const REPORT_PAYMENT_API = 'http://192.169.176.97/rest/api/reports/payments'
const REPORT_EXPENSES_API = 'http://192.169.176.97/rest/api/reports/expenses'
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { year: 'numeric', month: 'short', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

const paymentTypes = {
  "1": "Efectivo",
  "2": "Tarjeta",
  "3": "Transferencia",
}

const paymentStatus = {
  "1": "Pendiente",
  "2": "Pagado",
  "3": "Cancelado",
  "4": "Reembolsado",
  "5": "Adelanto",
  "6": "Ingreso adicional al evento",
}

function Reports({ setGlobalLoading }) {
  var date = new Date()

  const [ eventList, setEventList ] = useState({ events: [], eventsSumary: [] })
  const [ payments, setPayments ] = useState({ payments: [], paymentSumary: [] })
  const [ expenses, setExpenses ] = useState({ expenses: [], expensesSumary: [] })

  const [ data, setData ] = useState([])
  const [ dataEvents, setDataEvents ] = useState([])
  const [ dataExpenses, setDataExpenses ] = useState([])
  const [ start, setStart ] = useState(date.getFullYear() + "-" + (date.getMonth() < 10 ? ('0' + (parseInt(date.getMonth()) + 1)) : (parseInt(date.getMonth()) + 1)) + "-01")
  const [ end, setEnd ] = useState(date.getFullYear() + "-" + (date.getMonth() < 10 ? ('0' + (parseInt(date.getMonth()) + 2)) : (parseInt(date.getMonth()) + 2)) + "-01")
  const [ range, setRange ] = useState('yyyy-MM-dd')
  const [ tabName, setTabName ] = useState('event')

  const [ totalEvents, setTotalEvents ] = useState(0)
  const [ totalPayments, setTotalPayments ] = useState(0)
  const [ totalExpenses, setTotalExpenses ] = useState(0)

  const [ totalExpensesCancel, setTotalExpensesCancel ] = useState(0)
  const [ totalPaymentsCancel, setTotalPaymentsCancel ] = useState(0)
  const [ totalPaymentsExtra, setTotalPaymentsExtra ] = useState(0)

  const { showPaymentForm, setShowPaymentForm } = useContext(GlobalContext);

  useEffect(() => {
    search()
  }, []);

  const search = () => {
    getPayments()
    getExpenses()
    getEvents()
  }

  const getPayments = () => {
    setGlobalLoading(true)

    console.log("date", start, new Date( start + " 00:00:00" ).getTime())

    axios({
      method: 'get',
      url: REPORT_PAYMENT_API + '' + '/' + new Date( start + " 00:00:00" ).getTime() + '/' + new Date( end + " 23:59:59" ).getTime() + '/' + range,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("result payments", result.data)
      var pays = result.data.paymentSumary
      var paysList = result.data.payments
      var dataTable = []
      var total = 0
      var totalCancel = 0
      var totalExtra = 0
      
      /*dataTable.push([ "Fecha", "Ingreso" ])

      pays.map(item => {
        dataTable.push([ item.date, item.amount ])
      })*/

      pays.map(item => {
        var date = item.date.split('-')
        var dateValue = null
        var dateFormat = ""

        if(range == "yyyy"){ 
          dateValue = new Date( date[0] )
          dateFormat = "YYYY"
        } else if(range == "yyyy-MM"){ 
          dateValue = new Date( date[0], parseInt(date[1]) - 1 )
          dateFormat = "MMM YYYY" 
        } else if(range == "yyyy-MM-dd") {
          dateValue = new Date( date[0], parseInt(date[1]) - 1, date[2] )
          dateFormat = "DD MMM YYYY"
        }

        dataTable.push({ name: dayjs( dateValue ).locale("es").format( dateFormat ), Monto: item.amount })
        total += item.amount;
      })

      paysList.map(item => {
        console.log('payment status', item.paymentStatusId)
        if( item.paymentStatusId == 3 ) totalCancel += 1
        else if( item.paymentStatusId == 6 ) totalExtra += 1
      })

      setData(dataTable)
      setTotalPayments(total)
      setTotalPaymentsCancel(totalCancel)
      setTotalPaymentsExtra(totalExtra)

      setPayments(result.data)
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const getExpenses = () => {
    setGlobalLoading(true)

    axios({
      method: 'get',
      url: REPORT_EXPENSES_API + '' + '/' + new Date( start + " 00:00:00" ).getTime() + '/' + new Date( end + " 23:59:59" ).getTime() + '/' + range,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("result expenses", result.data)
      var pays = result.data.expensesSumary
      var paysList = result.data.expenses
      var dataTable = []
      var total = 0
      var totalCancel = 0
      /*dataTable.push([ "Fecha", "Gastos" ])

      pays.map(item => {
        dataTable.push([ item.date, item.amount ])
      })*/

      pays.map(item => {
        var date = item.date.split('-')
        var dateValue = null
        var dateFormat = ""

        if(range == "yyyy"){ 
          dateValue = new Date( date[0] )
          dateFormat = "YYYY"
        } else if(range == "yyyy-MM"){ 
          dateValue = new Date( date[0], parseInt(date[1]) - 1 )
          dateFormat = "MMM YYYY" 
        } else if(range == "yyyy-MM-dd") {
          dateValue = new Date( date[0], parseInt(date[1]) - 1, date[2] )
          dateFormat = "DD MMM YYYY"
        }

        dataTable.push({ name: dayjs( dateValue ).locale("es").format( dateFormat ), Monto: item.amount })
        total += item.amount;
      })

      paysList.map(item => {
        if( item.paymentStatusId == 5 ) totalCancel += 1
      })

      setDataExpenses( dataTable )
      setTotalExpensesCancel( totalCancel )

      setExpenses(result.data)
      setTotalExpenses( total )
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const getEvents = () => {
    setGlobalLoading(true)

    axios({
      method: 'get',
      url: REPORT_EVENT_API + '' + '/' + new Date( start + " 00:00:00" ).getTime() + '/' + new Date( end + " 23:59:59" ).getTime() + '/' + range,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("result events", result.data)
      var pays = result.data.eventsSumary
      var dataTable = []
      var temp = []
      /*dataTable.push([ "Fecha", "Eventos" ])

      pays.map(item => {
        var result = temp.find(({ date }) => date === item.date);

        if( result ) result.count += item.count
        else temp.push({ date: item.date, count: item.count })
      })

      temp.map( item => {
        dataTable.push([ item.date, item.count ])
      })*/

      pays.map(item => {
        var result = temp.find(({ date }) => date === item.date);
        var date = item.date.split('-')
        var dateValue = null
        var dateFormat = ""

        if(range == "yyyy"){ 
          dateValue = new Date( date[0] )
          dateFormat = "YYYY"
        } else if(range == "yyyy-MM"){ 
          dateValue = new Date( date[0], parseInt(date[1]) - 1 )
          dateFormat = "MMM YYYY" 
        } else if(range == "yyyy-MM-dd") {
          dateValue = new Date( date[0], parseInt(date[1]) - 1, date[2] )
          dateFormat = "DD MMM YYYY"
        }

        if( result ) result.count += item.count
        else dataTable.push({ name: dayjs( dateValue ).locale("es").format( dateFormat ), Eventos: item.count })
        //dataTable.push({ name: dayjs(new Date( date[0], date[1], date[2] )).locale("es").format( "DD MMM YYYY" ), Eventos: item.count })
      })

      console.log("dataTable", dataTable)

      setDataEvents(dataTable)
      setEventList(result.data)
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const evalStatus = ( status ) => {
    if( status == 2 || status == 5 ) return 'status-ok-icon'
    else if( status == 6 ) return 'status-extra-icon'
    else return 'status-cancel-icon'
  }

  const paymentStatus = {
    "1": "Pendiente",
    "2": "Pagado",
    "3": "Cancelado",
    "4": "Reembolsado",
    "5": "Adelanto",
    "6": "Ingreso adicional al evento",
  }

  return (
    <>
      <Container>
        <Row className="align-items-center">
          <Col md="4">
            <Form.Group className='mb-3'>
              <FloatingLabel controlId="floatingPassword" label="Fecha de Inicio">
                <Form.Control required type="date" name="start" value={ start } placeholder="" onChange={ (e) => setStart(e.target.value) }/>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group className='mb-3'>
              <FloatingLabel controlId="floatingPassword" label="Fecha Fin">
                <Form.Control required type="date" name="end" value={ end } placeholder="" onChange={ (e) => setEnd(e.target.value) }/>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group className='mb-3'>
              <FloatingLabel controlId="floatingPassword" label="Rango">
                <Form.Select name="paymentType" value={ range } onChange={ (e) => setRange(e.target.value) }>
                  <option value="yyyy">Año</option>
                  <option value="yyyy-MM">Mes</option>
                  <option value="yyyy-MM-dd">Día</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="2">
            <div align="right">
              <button onClick={ () => search() } type="button" className="grl-btn">{ 'BUSCAR' }</button>
            </div>
          </Col>
        </Row>
      </Container>

      <Tabs activeKey={ tabName } className="mb-3" onSelect={(k) => setTabName(k)}>
        <Tab eventKey="event" title="Eventos"></Tab>
        <Tab eventKey="payment" title="Ingresos"></Tab>
        <Tab eventKey="expense" title="Egresos"></Tab>
      </Tabs>
          <Container className={ tabName == 'event' ? '' : 'hide' }>
            <Row>
              <Col md={ 4 }>
                <div className='main-component card-violet-alt-2'>
                  <div className="section-subtitle" style={{ color: '#fff', fontSize: '40px', lineHeight: '50px' }}> Eventos </div>
                  <div className="section-subtitle subtitle-alt" style={{ color: '#fff' }}> {  } </div>
                  
                  <br/>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Total Eventos</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ eventList.events.length }</div>
                </div>
              </Col>
              <Col md={ 8 }>
                <Container className='main-component'>
                  <Row>
                    <Col>
                      {/*<div className="section-subtitle"> Eventos </div>
                       <Chart
                        chartType="LineChart"
                        data={ dataEvents }
                        options={ {
                          hAxis: { title: "Fecha" },
                          vAxis: { title: "Eventos", vAxis: {minValue: 0} },
                          legend: "none"
                        } }
                        width="100%"
                        height="500px"
                        legendToggle
                      /> */}
                      <ResponsiveContainer width="100%" height={400}>
                        <AreaChart 
                          width={900} 
                          height={400} 
                          data={dataEvents}
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <defs>
                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 'dataMax + 1']} allowDecimals={ false } />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Area type="monotone" dataKey="Eventos" stroke="#8884d8" fillOpacity={1} activeDot={{ r: 8 }} fill="url(#colorPv)" />
                        </AreaChart>

                        {/*<LineChart
                          width={900}
                          height={400}
                          data={dataEvents}
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 'dataMax + 1']} allowDecimals={ false } />
                          <Tooltip />
                          <Line type="monotone" dataKey="Eventos" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>*/}
                      </ResponsiveContainer>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <br/>
            <Row>  
              <Col md={ 12 }>
                <Container className='main-component'>
                  <Row>
                    <Col md={ 12 }>
                      <div className="section-subtitle"> Lista de Eventos </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Evento</th>
                            <th>Paquete</th>
                            <th>Número de Personas</th>
                            <th>Costo</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          { eventList.events.map( item => (
                            <tr>
                              <td> 
                                <div className="sub-date">{ DATE_FORMAT.format((new Date(item.eventDate)).getTime()) } </div>
                                <div> { item.eventName } </div>
                              </td>
                              <td> { item.packageName } </td>
                              <td> { item.peopleNumber } </td>
                              <td> { CURRENCY_FORMAT.format(item.cost) } </td>
                            </tr>
                          )) }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>

          <Container className={ tabName == 'payment' ? '' : 'hide' }>
            <Row>
              <Col md={ 8 }>
                <Container className='main-component'>
                  <Row>
                    <Col>
                      {/*<div className="section-subtitle"> Ingresos </div>
                      <Chart
                        chartType="LineChart"
                        data={ data }
                        options={ {
                          hAxis: { title: "Fecha" },
                          vAxis: { title: "Ingreso", vAxis: {minValue: 0} },
                          legend: "none"
                        } }
                        width="100%"
                        height="500px"
                        legendToggle
                      />*/}
                      <ResponsiveContainer width="100%" height={400}>
                        <AreaChart 
                          width={900} 
                          height={400} 
                          data={data}
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <defs>
                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 'dataMax + 1']} allowDecimals={ false } />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Area type="monotone" dataKey="Monto" stroke="#82ca9d" fillOpacity={1} activeDot={{ r: 8 }} fill="url(#colorPv)" />
                        </AreaChart>

                        {/*<LineChart
                          width={900}
                          height={400}
                          data={data}
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 'dataMax + 5']} allowDecimals={ false } />
                          <Tooltip />
                          <Line type="monotone" dataKey="Monto" stroke="#84d891" activeDot={{ r: 8 }} />
                        </LineChart>*/}
                      </ResponsiveContainer>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md={ 4 }>
                <div className='main-component card-violet-alt card-violet-alt-payment'>
                  <div className="section-subtitle" style={{ color: '#fff', fontSize: '40px', lineHeight: '50px' }}> Ingresos </div>
                  <div className="section-subtitle subtitle-alt" style={{ color: '#fff' }}> {  } </div>
                  
                  <br/>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Total Ingresos</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ CURRENCY_FORMAT.format( totalPayments ) }</div>

                  <br/>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Total Transacciones</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ payments.payments.length }</div>

                  <br/>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Total Cancelados</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ totalPaymentsCancel }</div>

                  <br/>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Total Extra</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ totalPaymentsExtra }</div>

                </div>
              </Col>
            </Row>
            <br/>
            <Row>  
              <Col md={ 12 }>
                <Container className='main-component'>
                  <Row>
                    <Col md={ 12 }>
                      <div className="section-subtitle"> Lista de Ingresos </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Concepto</th>
                            <th>Evento</th>
                            <th>Tipo de pago</th>
                            <th>Estatus</th>
                            <th>Monto</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          { payments.payments.map( item => (
                            <tr>
                              <td> 
                                <div className="sub-date">{ DATE_FORMAT.format((new Date(item.dateApplication)).getTime()) } </div>
                                <div> { item.description } </div>
                              </td>
                              <td>
                                <div className="sub-date">{ DATE_FORMAT.format((new Date(item.basicEvent.eventDate)).getTime()) } </div>
                                <div> { item.basicEvent.eventName } </div>
                              </td>
                              <td> { item.paymentType.name } </td>
                              <td>
                                <div className={ evalStatus(item.paymentStatusId) }> 
                                  { paymentStatus[item.paymentStatusId.toString()] }
                                </div>
                              </td>
                              <td> { CURRENCY_FORMAT.format(item.paymentAmount) } </td>
                            </tr>
                          )) }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>

          <Container className={ tabName == 'expense' ? '' : 'hide' }>
            <Row>
              <Col md={ 4 }>
                <div className='main-component card-violet-alt card-violet-alt-expenses'>
                  <div className="section-subtitle" style={{ color: '#fff', fontSize: '40px', lineHeight: '50px' }}> Egresos </div>
                  <div className="section-subtitle subtitle-alt" style={{ color: '#fff' }}> {  } </div>
                  
                  <br/>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Total Egresos</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ CURRENCY_FORMAT.format( totalExpenses ) }</div>

                  <br/>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Total Transacciones</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ expenses.expenses.length }</div>

                  <br/>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Total Cancelados</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ totalExpensesCancel }</div>
                </div>
              </Col>
              <Col md={ 8 }>
                <Container className='main-component'>
                  <Row>
                    <Col>
                      {/*<div className="section-subtitle"> Gastos </div>
                      <Chart
                        chartType="LineChart"
                        data={ dataExpenses }
                        options={ {
                          hAxis: { title: "Fecha" },
                          vAxis: { title: "Gasto", vAxis: {minValue: 0} },
                          legend: "none"
                        } }
                        width="100%"
                        height="500px"
                        legendToggle
                      />*/}
                      <ResponsiveContainer width="100%" height={400}>
                        <AreaChart 
                          width={900} 
                          height={400} 
                          data={dataExpenses}
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <defs>
                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#d88484" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#d88484" stopOpacity={0}/>
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 'dataMax + 1']} allowDecimals={ false } />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Area type="monotone" dataKey="Monto" stroke="#d88484" fillOpacity={1} activeDot={{ r: 8 }} fill="url(#colorPv)" />
                        </AreaChart>

                        {/*<LineChart
                          width={900}
                          height={400}
                          data={dataExpenses}
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 'dataMax + 5']} allowDecimals={ false } />
                          <Tooltip />
                          <Line type="monotone" dataKey="Monto" stroke="#d88484" activeDot={{ r: 8 }} />
                        </LineChart>*/}
                      </ResponsiveContainer>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <br/>
            <Row>  
              <Col md={ 12 }>
                <Container className='main-component'>
                  <Row>
                    <Col md={ 12 }>
                      <div className="section-subtitle"> Lista de Egresos </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Concepto</th>
                            <th>Estatus</th>
                            <th>Monto</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          { expenses.expenses.map( item => (
                            <tr>
                              <td> 
                                <div className="sub-date">{ DATE_FORMAT.format((new Date(item.dateApplication)).getTime()) } </div>
                                <div> { item.expenseName } </div>
                              </td>
                              <td>
                                <div className={ item.paymentStatusId == 2 || item.paymentStatusId == 5 ? 'status-ok-icon' : 'status-cancel-icon' }> 
                                  { paymentStatus[item.paymentStatusId.toString()] }
                                </div>
                              </td>
                              <td> { CURRENCY_FORMAT.format(item.amount) } </td>
                            </tr>
                          )) }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
    </>
  );
}

export default Reports;
