import React, { useState, useEffect, useContext } from "react";
import axios from 'axios'
//import { Chart } from "react-google-charts";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import ReactFlipCard from 'reactjs-flip-card'

import Charges from "./Charges"
import PaymentForm from "./PaymentForm";
import Print from "./Print";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'

import GlobalContext from "./context/GlobalContext";

import './App.css';
import { ArrowLeftRight, PrinterFill, Wallet2, X } from "react-bootstrap-icons";

import m_payments from './img/payments.png'
import { isMobile } from "react-device-detect";

const PAYMENTS_API = 'http://192.169.176.97/rest/api/payments/getAllPaymentsMonth'
const PAYMENTS_MONTH_API = 'http://192.169.176.97/rest/api/payments/getPaymentsMonth'
const CANCEL_API = 'http://192.169.176.97/rest/api/payments/cancelPayment/'
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { year: 'numeric', month: 'short', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

const paymentTypes = {
  "1": "Efectivo",
  "2": "Tarjeta",
  "3": "Transferencia",
}

const paymentStatus = {
  "1": "Pendiente",
  "2": "Pagado",
  "3": "Cancelado",
  "4": "Reembolsado",
  "5": "Adelanto",
  "6": "Ingreso adicional al evento",
}

const options = {
  hAxis: { title: "Día" },
  vAxis: { title: "Ingreso", vAxis: {minValue: 0} },
  legend: "none"
};

function Payments({ setGlobalLoading }) {
  const [ eventList, setEventList ] = useState([])
  const [ eventItem, setEventItem ] = useState({ "nameEvent": "", "costEvent": 0, "advancePay": 0 })
  const [ payments, setPayments ] = useState([])
  const [ show, setShow ] = useState(false)
  const [ showPayments, setShowPayments ] = useState(false)
  //const [ showPaymentForm, setShowPaymentForm ] = useState(false)
  const [ action, setAction ] = useState('Crear Evento')
  const [ showPrintForm, setShowPrintForm ] = useState(false)
  const [ paymentSelected, setPaymentSelected ] = useState([])
  const [ paymentChange, setPaymentChange ] = useState(0)

  const [ okEvents, setOkEvents ] = useState(0)
  const [ okPayments, setOkPayments ] = useState(0) 
  const [ cancelEvents, setCancelEvents ] = useState(0)
  const [ cancelPayments, setCancelPayments ] = useState(0)  
  const [ data, setData ] = useState([])
  const [ currentDate, setCurrentDate ] = useState(null)

  const { showPaymentForm, setShowPaymentForm, currentTotalEvents, totalCharge, countCharge, countSales } = useContext(GlobalContext);

  const dataC = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}, {name: 'Page B', uv: 200, pv: 2400, amt: 2400}, {name: 'Page C', uv: 700, pv: 2400, amt: 2400}];

  useEffect(() => {
    setCurrentDate( dayjs(new Date( dayjs().year(), dayjs().month() )).locale("es").format( "MMMM YYYY" ) )
    getPayments()
  }, []);

  const getPayments = () => {
    setGlobalLoading(true)

    axios({
      method: 'get',
      url: PAYMENTS_API,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("balance", result.data)
      var pays = result.data
      var ok_e = 0; 
      var ok_p = 0; 
      var cancel_e = 0; 
      var cancel_p = 0; 
      var dataTable = []
      var temp = []
      dataTable.push([ "Día", "Ingreso" ])

      pays.map(item => {
        if( item.paymentStatusId == 2 || item.paymentStatusId == 5 || item.paymentStatusId == 6) {
          ok_e ++;
          ok_p += item.paymentAmount;

          var result = temp.find(({ day }) => day === new Date(item.dateApplication).getDate());

          if( result ) result.amount += item.paymentAmount
          else temp.push({ day: new Date(item.dateApplication).getDate(), amount: item.paymentAmount })

        } if( item.paymentStatusId == 3 ) {
          cancel_e ++;
          cancel_p += item.paymentAmount;
        }
      })

      temp.map( item => {
        dataTable.push([ item.day, item.amount ])
      })

      console.log("dataTable", dataTable)
      setData(dataTable)
      setPayments(pays)
      setOkEvents(ok_e)
      setOkPayments(ok_p)
      setCancelEvents(cancel_e)
      setCancelPayments(cancel_p)
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const getPaymentsByMounth = () => {
    setGlobalLoading(true)

    axios({
      method: 'get',
      url: PAYMENTS_MONTH_API,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("balance", result.data)
      var pays = result.data
      var ok_e = 0; 
      var ok_p = 0;

      pays.map(item => {
        if( item.paymentStatusId == 2 || item.paymentStatusId == 5 || item.paymentStatusId == 6) {
          ok_e ++;
          ok_p += item.paymentAmount;
        }
      })

      setPayments(pays)
      setOkEvents(ok_e)
      setOkPayments(ok_p)
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const handlePaymentTypeOnChange = () => {
    var status = paymentChange == 0 ? 1 : 0
    setPaymentChange( status )

    if( status == 1 ) getPaymentsByMounth()
    else getPayments()
  }

  const getPaymentForm = () => {
    setShowPaymentForm(true)
  }

  const cancelPayment = (id) => {
    setGlobalLoading(true)

    axios({
      method: 'get',
      url: CANCEL_API + id,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("result cancel", result.data)
      getPayments()
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const printTicket = (element) => {
    setShowPrintForm(true)
    setPaymentSelected(element)
  }

  const evalStatus = ( status ) => {
    if( status == 2 || status == 5 ) return 'status-ok-icon'
    else if( status == 6 ) return 'status-extra-icon'
    else return 'status-cancel-icon'
  }

  return (
    <>

      <Modal show={showPaymentForm}>
        <Modal.Body>
          <PaymentForm />
        </Modal.Body>
      </Modal>

      <Modal size={ 'xl' } show={showPrintForm}>
        <Modal.Body>
          <Print payment={ paymentSelected } />
          <br/>
          <div align="right">
            <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowPrintForm(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
          </div>
        </Modal.Body>
      </Modal>

      <Container>
        <Row>
          <Col md={ 12 }>
            <Container className='main-component card-lightblue'>
              <Row>
                <Col md={ 7 } className="card-box-left">
                  <div className="section-subtitle" style={{ fontSize: '40px', lineHeight: '50px' }}> Resumen Financiero </div>
                  <div className="section-subtitle subtitle-alt"> { currentDate } </div>
                  <br/>
                  <div className="description-style"> Ingresos y egresos correspondientes a los eventos que se llevaron o se llevarán a cabo en el mes corriente. </div>
                  <br/>
                  <Container>
                    <Row>
                      <Col md={ 6 } xs={ 12 }>
                        <div className="small-title" style={{ color: '#000', fontWeight: 400 }}>Total de Eventos</div>
                        <div className="medium-title" style={{ color: '#000' }}>{ currentTotalEvents }</div>
                        <br/>
                      </Col>
                      <Col md={ 6 } xs={ 12 }>
                        <div className="small-title" style={{ color: '#000', fontWeight: 400 }}>Venta del mes</div>
                        <div className="medium-title" style={{ color: '#000' }}>{ CURRENCY_FORMAT.format( countSales ) }</div>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col md={ 5 } className="payment-back"></Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <br/>

      <Container>
        <Row>
          <Col md={ 4 } xs={ 12 }>
            <ReactFlipCard
              onClick={ () => handlePaymentTypeOnChange()}
              containerStyle={{ width: '100%' }}
              flipTrigger='onClick'
              frontComponent={
                <Container className='main-component card-purple'>
                  <Row>
                    <Col md={ 12 }>
                    <div style={{ position: 'relative' }}>
                      <div className="section-subtitle-small" style={{ color: '#fff', lineHeight: '50px' }}> Ingresos </div>
                      <div className="change-style"> <ArrowLeftRight className="change-icon" /> </div>
                    </div>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md={ 'auto' }>
                      <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Mov.</div>
                      <div className="medium-title-small" style={{ color: '#fff' }}>{ okEvents }</div>
                    </Col>
                    <Col>
                      <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Monto</div>
                      <div className="medium-title-small" style={{ color: '#fff' }}>{ CURRENCY_FORMAT.format( okPayments ) }</div>
                    </Col>
                  </Row>
                </Container>
              }
              backComponent={
                <Container className='main-component card-green'>
                  <Row>
                    <Col md={ 12 }>
                      <div style={{ position: 'relative' }}>
                        <div className="section-subtitle-small" style={{ color: '#fff', lineHeight: '50px' }}> Ingresos Eventos </div>
                        <div className="change-style"> <ArrowLeftRight className="change-icon" /> </div>
                      </div>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col md={ 'auto' }>
                      <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Mov.</div>
                      <div className="medium-title-small" style={{ color: '#fff' }}>{ okEvents }</div>
                    </Col>
                    <Col>
                      <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Monto</div>
                      <div className="medium-title-small" style={{ color: '#fff' }}>{ CURRENCY_FORMAT.format( okPayments ) }</div>
                    </Col>
                  </Row>
                </Container>
              }
            />
          </Col>
          <Col md={ 4 } xs={ 12 }>
            <Container className='main-component card-red'>
              <Row>
                <Col md={ 12 }>
                  <div className="section-subtitle-small" style={{ color: '#fff', lineHeight: '50px' }}> Egresos </div>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={ 'auto' }>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Mov.</div>
                  <div className="medium-title-small" style={{ color: '#fff' }}>{ countCharge }</div>
                </Col>
                <Col>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Monto</div>
                  <div className="medium-title-small" style={{ color: '#fff' }}>{ CURRENCY_FORMAT.format( totalCharge ) }</div>
                </Col>
              </Row>
            </Container>

          </Col>
          <Col md={ 4 } xs={ 12 }>
            <Container className='main-component card-yellow'>
              <Row>
                <Col md={ 12 }>
                  <div className="section-subtitle-small" style={{ color: '#fff', lineHeight: '50px' }}> Balance </div>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={ 12 }>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Diferencia</div>
                  <div className="medium-title-small" style={{ color: '#fff' }}>{ CURRENCY_FORMAT.format( okPayments - totalCharge ) }</div>
                </Col>
              </Row>
            </Container>

          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={ 6 } xs={ 12 } className={ !isMobile ? "col-md-6-custom" : "" }>
            <Container className='main-component table-style'>
              <Row>  
                <Col md={ 12 }>
                  <Container>
                    <Row className="align-items-center">
                      <Col md={ 9 }>
                        <div className="section-subtitle"> Listado de Ingresos </div>
                      </Col>
                      { !isMobile && 
                        <Col md={ 3 } align="right">
                          <div className="single-btn" onClick={ () => getPaymentForm() } > Nuevo </div>
                        </Col>
                      }
                    </Row>
                    <br/>
                    <Row>
                      <Col>
                        <Table responsive className="table-scroll">
                          <thead>
                            <tr>
                              <th>Concepto</th>
                              <th>Evento</th>
                              <th>Monto</th>
                              <th>Estatus</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            { payments.map( item => (
                              <tr>
                                <td> 
                                  <div className="sub-date">{ DATE_FORMAT.format((new Date(item.dateApplication)).getTime()) } </div>
                                  <div> { item.description } </div>
                                </td>
                                <td> 
                                  <div className="sub-date">{ DATE_FORMAT.format((new Date(item.basicEvent.eventDate)).getTime()) } </div>
                                  <div> { item.basicEvent.eventName } </div>
                                </td>
                                <td> { CURRENCY_FORMAT.format(item.paymentAmount) } </td>
                                <td>
                                  <div align="center"> 
                                    <span className={ evalStatus(item.paymentStatusId) }>{ paymentStatus[item.paymentStatusId.toString()] }</span>
                                  </div>
                                </td>
                                <td> 
                                  {/*<PrinterFill onClick={ () => printTicket(item) } className="table-icon" />*/}
                                  { (!isMobile && item.paymentStatusId != 3) && 
                                    <> <X onClick={ () => cancelPayment(item.paymentId) } className="table-icon" /> </>
                                  }
                                </td>
                              </tr>
                            )) }
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={ 6 } xs={ 12 } className={ !isMobile ? "col-md-6-custom" : "" } style={ isMobile ? { marginTop: '20px' } : {} }>
            <Charges setGlobalLoading={ setGlobalLoading }/>
          </Col>
        </Row>
        {/*<br/>
        <Row>  
          <Col md={ 12 }>
            <Container className='main-component'>
              <Row>
                <Col md={ 9 }>
                  <div className="section-subtitle"> Lista de pagos </div>
                </Col>
                <Col md={ 3 } align="right">
                  <div className="single-btn" onClick={ () => getPaymentForm() } > Nuevo </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th>Monto</th>
                        <th>Tipo de Pago</th>
                        <th>Estatus</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { payments.map( item => (
                        <tr>
                          <td> { DATE_FORMAT.format((new Date(item.dateApplication)).getTime()) } </td>
                          <td> { item.description } </td>
                          <td> { CURRENCY_FORMAT.format(item.paymentAmount) } </td>
                          <td> { item.paymentType.name } </td>
                          <td>
                            <div className={ evalStatus(item.paymentStatusId) }> 
                              { paymentStatus[item.paymentStatusId.toString()] }
                            </div>
                          </td>
                          <td> 
                            //<PrinterFill onClick={ () => printTicket(item) } className="table-icon" />
                            {  item.paymentStatusId != 3 && 
                              <> <X onClick={ () => cancelPayment(item.paymentId) } className="table-icon" /> </>
                            }
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>*/}
      </Container>
      <br/>
      <br/>
    </>
  );
}

export default Payments;
