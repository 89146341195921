import React, { useState, useEffect, useContext } from "react";
import axios from 'axios'
import dayjs from "dayjs";
import { packages, extras } from './eventsJson'
import PaymentForm from "./PaymentForm";
import { isMobile } from "react-device-detect";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import Select from 'react-select'

import { getMonth } from "./util";
import CalendarHeader from "./components/CalendarHeader";
import Sidebar from "./components/Sidebar";
import Month from "./components/Month";
import GlobalContext from "./context/GlobalContext";
import EventModal from "./components/EventModal";

import './App.css';
import { Check, InfoCircleFill, PencilFill, Wallet2, X } from "react-bootstrap-icons";

const UPDATE_API = 'http://192.169.176.97/rest/api/events/addEvent/'
const PACKAGES_API = 'http://192.169.176.97/rest/api/paquetes/packages'
const ITEMS_API = 'http://192.169.176.97/rest/api/paquetes/items'
const EXTRA_API = 'http://192.169.176.97/rest/api/events/addPackageEvent/'
const GET_EXTRA_API = 'http://192.169.176.97/rest/api/events/getPackageExternal/'
const REMOVE_EXTRA_API = 'http://192.169.176.97/rest/api/events/removePackageExternal/'
const EXTERNAL_API = 'http://192.169.176.97/rest/api/events/addPackageExternal/'
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hour: "numeric", minute: "numeric", hour12: false, timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })
const DATE_FORMAT_SHORT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })


const paymentTypes = {
  "1": "Efectivo",
  "2": "Tarjeta",
  "3": "Transferencia",
}

const paymentStatus = {
  "1": "Pendiente",
  "2": "Pagado",
  "3": "Cancelado",
  "4": "Reembolsado",
  "5": "Adelanto",
}

function EventForm( { days, setGlobalLoading }) {
  const [ eventList, setEventList ] = useState([])
  const [ eventItem, setEventItem ] = useState({ "eventName": "", "cost": 0, "advancePay": 0 })
  const [ balance, setBalance ] = useState({ "amount": 0, "payments": []  })
  const [ show, setShow ] = useState(false)
  const [ showPayments, setShowPayments ] = useState(false)
  const [ showPaymentForm, setShowPaymentForm ] = useState(false)
  const [ action, setAction ] = useState('Crear Evento')
  const [key, setKey] = useState('step1');

  const [ eventId, setEventId ] = useState(null)
  const [ eventName, setEventName ] = useState(null)
  const [ eventDate, setEventDate ] = useState('2017-06-01')
  const [ eventDateMobile, setEventDateMobile ] = useState('2017-06-01')
  const [ hourEvent, setHourEvent ] = useState({})
  const [ advancePay, setAdvancePay ] = useState(0.0)
  const [ packageEvent, setPackageEvent ] = useState()
  const [ packageId, setPackageId ] = useState(null)
  const [ packagePeopleId, setPackagePeopleId ] = useState(null)
  const [ packagePriceId, setPackagePriceId ] = useState(null)
  const [ peopleId, setPeopleId ] = useState(null)
  const [ cost, setCost ] = useState(0.0)
  const [ clientAddress, setClientAddress ] = useState('')
  const [ clientEmail, setClientEmail ] = useState('')
  const [ clientName, setClientName ] = useState('')
  const [ clientPhoneNumber, setClientPhoneNumber ] = useState('')
  const [ eventStatusId, setEventStatusId ] = useState('')
  const [ cancelDate, setCancelDate ] = useState('')
  const [ userCancelEvent, setUserCancelEvent ] = useState('')
  const [ notes, setNotes ] = useState('')
  const [ iva, setIva ] = useState('')
  const [ extraCost, setExtraCost ] = useState('')
  const [ packageCost, setPackageCost ] = useState(0.0)
  const [ discountType, setDiscountType ] = useState('percent')
  const [ discount, setDiscount ] = useState(null)
  const [ discountAmount, setDiscountAmount ] = useState(null)

  const [ externalList, setExternalList ] = useState([])
  const [ extraNotes, setExtraNotes ] = useState('')
  const [ concept, setConcept ] = useState('')
  const [ extraPrice, setExtraPrice ] = useState(0)
  const [ category, setCategory ] = useState('General')
  const [ externalId, setExternalId ] = useState(null)
  const [ externalTotal, setExternalTotal ] = useState(0)
  const [ schedule, setSchedule ] = useState([])
  
  const [ people, setPeople ] = useState(null)
  const [ checkedValues, setCheckedValues ] = useState([])
  const [ checkedValuesCount, setCheckedValuesCount ] = useState([])
  const [ holiDay, setHoliDay ] = useState(false)
  const [ putIva, setPutIva ] = useState(false)

  const [ packages, setPackages ] = useState([])
  const [ pItems, setPItems ] = useState([])
  const [ realItems, setRealItems ] = useState([])
  const [ state, setState ] = useState({})
  
  const { showEventModal, setShowEventModal, filteredEvents, setShowEventForm, 
    selectedEvent, daySelected, dateSelected, dayEventsMobile } = useContext(GlobalContext);

  const HOURS = [
    { value: '10', label: '10:00 a 15:00 hrs' },
    { value: '16', label: '16:00 a 21:00 hrs' },
    { value: '12', label: '12:00 a 17:00 hrs' },
    { value: '13', label: '13:00 a 18:00 hrs' },
    { value: '14', label: '14:00 a 19:00 hrs' },
    { value: '15', label: '15:00 a 20:00 hrs' },
  ]

  const groupedOptions = [
    {
      label: 'Matutino',
      options: [{ value: '10', label: '10:00 a 15:00 hrs', color: "#00B8D9", isDisabled: false }]
    },
    {
      label: 'Vespertino',
      options: [{ value: '16', label: '16:00 a 21:00 hrs', color: "#00B8D9", isDisabled: false }]
    },
    {
      label: 'Mixto',
      options: [
        { value: '12', label: '12:00 a 17:00 hrs', color: "#00B8D9", isDisabled: false },
        { value: '13', label: '13:00 a 18:00 hrs', color: "#00B8D9", isDisabled: false },
        { value: '14', label: '14:00 a 19:00 hrs', color: "#00B8D9", isDisabled: false },
        { value: '15', label: '15:00 a 20:00 hrs', color: "#00B8D9", isDisabled: false },
      ]
    }
  ]

  useEffect(() => {
    setGlobalLoading(true)
    getPackages()

    console.log("dayEventsMobile", dayEventsMobile)
  }, []);

  useEffect(() => {
    var gruped = groupedOptions;
    const events = filteredEvents.filter(
      (evt) =>
        dayjs(evt.eventDate).format("DD-MM-YY") === dateSelected.format("DD-MM-YY")
    );

    events.map( item => {
      gruped.map( label =>{
        label.options.map( schedule => {
          var hour = new Date( item.eventDate ).getHours()
          if( schedule.value == hour ) schedule.isDisabled = true
        })
      })
    })

    setSchedule( gruped )

  }, [filteredEvents, dateSelected]);

  const showEventFormInit = ( packagesList, tempItemList ) => {
    if(!selectedEvent) {
      let date  = new Date(daySelected)
      let dateP =  date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())

      setAction('Configura tu evento')
      setEventId(null)
      setEventName(null)
      setEventDate(dateP)
      setEventDateMobile( dateP + " 12:00" )
      setHourEvent(10)
      setAdvancePay(0.0)
      //setPackageEvent(null)
      setCost(0.0)
      setClientAddress('')
      setClientEmail('')
      setClientName('')
      setClientPhoneNumber('')
      setEventStatusId(1)
      setCancelDate(null)
      setPackagePeopleId(null)
      setPackagePriceId(null)
      setPackageId(null)
      setUserCancelEvent(null)
      setNotes(null)
      setPeopleId(null)
      setIva(0.0)
      setPutIva(false)
      setHoliDay(false)
      setExtraCost(0.0)
      setDiscountType('percent')
      setDiscount(null)
      setPackageCost(0.0)

      setExtraNotes('')
      setConcept('')
      setExtraPrice(0)
      setCategory('Show')
      setExternalId(null)

      setGlobalLoading(false)

    } else  {
      let date  = new Date(selectedEvent.eventDate)
      let dateP =  date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
      let hourP = date.getHours()

      setAction('Configura tu evento')
      setEventId(selectedEvent.eventId)
      setEventName(selectedEvent.eventName)
      setEventDate(dateP)
      setEventDateMobile( dateP + " 12:00" )
      setHourEvent( HOURS.find( (e) => e.value == hourP ) )
      setAdvancePay(selectedEvent.advancePay)
      //setPackageEvent(selectedEvent.packageEvent)
      setCost(selectedEvent.cost ? selectedEvent.cost : 0)
      setClientAddress(selectedEvent.clientAddress)
      setClientEmail(selectedEvent.clientEmail)
      setClientName(selectedEvent.clientName)
      setClientPhoneNumber(selectedEvent.clientPhoneNumber)
      setEventStatusId(selectedEvent.eventStatusId)
      setCancelDate(selectedEvent.cancelDate)
      setPackagePeopleId(selectedEvent.packagePeopleId)
      setPackagePriceId(selectedEvent.packagePriceId)
      setUserCancelEvent(selectedEvent.userCancelEvent)
      setNotes(selectedEvent.notes)
      setIva( selectedEvent.iva ? selectedEvent.iva : 0 )
      setPutIva( selectedEvent.putIva )
      setHoliDay( selectedEvent.holiDay )
      setExtraCost( selectedEvent.extrasCost ? selectedEvent.extrasCost : 0 )
      setDiscountType( selectedEvent.discountType ? selectedEvent.discountType : 'percent' )
      setDiscount( selectedEvent.discount )
      setDiscountAmount( selectedEvent.discountAmount )
      setPackageCost( selectedEvent.packageCost ? selectedEvent.packageCost : 0 )

      var result = null
      var tempPackageEvent = null
      packagesList.map( pack => {
        pack.packagePeople.map( item => {
          if( item.packagePeopleId == selectedEvent.packagePeopleId) {
             result = item;
             tempPackageEvent = pack;
          }
        })
      })

      if(result) {
        setPackageEvent(tempPackageEvent)
        setPeople(result)
        setPackageId(result.packageId)
        setPeopleId(result.peopleId)
      }

      console.log("selectedEvent.packageEventItem", selectedEvent.packageEventItem)

      var activeItems = {}

      selectedEvent.packageEventItem.map( item => {
        activeItems['check' + item.itemId] = true
        activeItems['quantity_' + item.itemId] = item.quantity
      })
  
      setState(activeItems);
  
      console.log('activeItems', activeItems)

      var temporalList = selectedEvent.packageEventItem
      var checkedList = []

      temporalList.map( item => {
        var rest = null
        tempItemList.map( obj => {
          if(obj.itemId === item.itemId) rest = obj
        })

        console.log( "rest", temporalList, item, rest )

        checkedList.push({
          //"eventItemPackageId": null,
          //"eventId": selectedEvent.eventId,
          "itemId": item.itemId,
          //"itemName": rest.itemName,
          "itemPrice": rest ? rest.itemPrice : 0,
          "quantity": item.quantity,
          //"editable": true
        })
      })
      
      var count = 0;
      checkedList.map( item => {
        if( item.quantity > 0 ) count++;
      })

      setCheckedValuesCount(count)
      setCheckedValues(checkedList)
      
      if(tempPackageEvent) {
        //onPriceChange( tempPackageEvent.packagePeople, checkedList, selectedEvent.packagePeopleId, selectedEvent.holiDay, externalList, selectedEvent.putIva, selectedEvent.discountType, selectedEvent.discount )
        setCost( selectedEvent.cost )
        setPackageCost( selectedEvent.packageCost )
        setExtraCost( selectedEvent.extrasCost )
        setIva( selectedEvent.iva )
        setDiscountAmount( selectedEvent.discountAmount )
      }

      getExternal()
    }
  }

  const nextStep = () => {
    if(key == 'step1') {
      setKey('step2')
      updateItem()
      getExternal()
    } else if(key == 'step2') {
      setKey('step1')
      updateItem()
    }
  }

  const onChangeTab = ( keyName ) => {
    if(key == 'step1') {
      setKey('step2')
      getExternal()
    } else if(key == 'step2') {
      setKey('step1')
    }
  }

  const getPackages = () => {
    axios({
      method: 'get',
      url: PACKAGES_API,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("packages", result.data)
      setPackages(result.data)
      getItems(result.data)
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const getItems = (packagesResult) => {
    var tempPackageList = packagesResult;
    axios({
      method: 'get',
      url: ITEMS_API,
      headers: { 'content-type': 'application/json' },
    })
    .then(result => {
      console.log("Items", result.data)
      var rest = result.data
      setRealItems(rest)

      var tempList = []
      rest.map( item => { if( item.itemPrice != 0 ) tempList.push(item) })

      setPItems(tempList)
      showEventFormInit(tempPackageList, tempList)
    })
  }

  const getItemEntity = (itemId) => {
    var result = realItems.filter(obj => {
      return obj.itemId === itemId
    })

    return result[0].itemName
  }

  const evalItemExist = (itemId) => {
    var chackedList = checkedValues.filter(obj => {
      return obj.itemId === itemId
    })

    var itemList = pItems.filter(obj => {
      return obj.itemId === itemId
    })

    if(chackedList[0] != undefined && itemList[0] != undefined) return true
    else return false
  }

  const evalItemQuantity = (itemId) => {
    var chackedList = checkedValues.filter(obj => {
      return obj.itemId === itemId
    })

    var itemList = pItems.filter(obj => {
      return obj.itemId === itemId
    })

    if(chackedList[0] != undefined && itemList[0] != undefined) return chackedList[0].quantity
    else return null
  }

  const updateItem = (e) => {
    setGlobalLoading(true)
    let date = new Date( eventDate + " " + hourEvent.value + ":00:00" ).getTime()

    let json = {
      "eventId": eventId,
      "eventName": eventName,
      "eventDate": date,
      "packagePeopleId": packagePeopleId,
      "packagePriceId": packagePriceId,
      "cost": cost,
      "clientAddress": clientAddress,
      "clientEmail": clientEmail,
      "clientName": clientName,
      "clientPhoneNumber": clientPhoneNumber,
      "eventStatusId": eventStatusId,
      "cancelDate": cancelDate,
      "notes": notes,
      "iva": iva,
      "putIva": putIva,
      "putIva": putIva,
      "holiDay": holiDay,
      "extrasCost": extraCost,
      "packageCost": packageCost,
      "discountType": discountType,
      "discount": discount,
      "discountAmount": discountAmount
    }

    console.log("update event", json)

    axios({
      method: 'post',
      url: UPDATE_API,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
      data: json
    })
    .then(result => {
      console.log("result update", result.data)

      setEventId( result.data.eventId )
      if(key == 'step2') updatePackage()
      else setGlobalLoading(false)
      //setShowEventForm(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
      //setShowEventForm(false)
    })
  }

  const updatePackage = (e) => {
    let json = []

    checkedValues.map( item => {
      json.push({
        "eventItemPackageId": null,
        "itemId": item.itemId,
        "eventId": eventId,
        "editable": true,
        "quantity": item.quantity
      })
    })

    console.log("update extras", eventId, json)

    axios({
      method: 'post',
      url: EXTRA_API + eventId,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
      data: json
    })
    .then(result => {
      console.log("result extras", result.data)
      setGlobalLoading(false)
      setShowEventForm(false)
      setShowEventModal(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
      //setShowEventForm(false)
    })
  }

  const updateExternal = () => {
    let json = {}

    json = {
      "id": externalId,
      "eventId": eventId,
      "category": category,
      "name": concept,
      "comments": extraNotes,
      "price": extraPrice
    }

    console.log("update external", eventId, json)

    axios({
      method: 'post',
      url: EXTERNAL_API,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
      data: json
    })
    .then(result => {
      console.log("result extras", result.data)
      setExtraNotes('')
      setConcept('')
      setExtraPrice(0)
      setCategory('Show')
      setExternalId(null)

      setExternalList(result.data)
      onPriceChange( packageEvent.packagePeople, checkedValues, packagePeopleId, holiDay, result.data, putIva, discountType, discount );
      //setShowEventForm(false)
      //setShowEventModal(false)
    })
    .catch(error => {
      console.log("error", error.message)
      //setShowEventForm(false)
    })
  }

  const getExternal = () => {
    if(selectedEvent.eventId) {
      setGlobalLoading(true)

      axios({
        method: 'get',
        url: GET_EXTRA_API + selectedEvent.eventId,
        headers: { 'content-type': 'application/json' },
      })
      .then(result => {
        console.log("result extras", result.data)
        setExternalList( result.data )
        //onPriceChange( packageEvent.packagePeople, checkedValues, packagePeopleId, holiDay, result.data, putIva, discountType, discount );
        setGlobalLoading(false)
      })
      .catch(error => {
        console.log("error", error.message)
        setGlobalLoading(false)
        //setShowEventForm(false)
      })
    }
  }

  const removeExternal = (e) => {
    setGlobalLoading(true)

    axios({
      method: 'post',
      url: REMOVE_EXTRA_API + e.id,
      headers: { 'content-type': 'application/json' },
    })
    .then(result => {
      console.log("result extras", result.data)
      setExternalList( result.data )
      onPriceChange( packageEvent.packagePeople, checkedValues, packagePeopleId, holiDay, result.data, putIva, discountType, discount );
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
      //setShowEventForm(false)
    })
  }

  const editExternal = (e) => {
    setExtraNotes(e.comments)
    setConcept(e.name)
    setExtraPrice(e.price)
    setCategory(e.category)
    setExternalId(e.id)
  }

  const cleanExtraForm = () => {
    setExtraNotes('')
    setConcept('')
    setExtraPrice(0)
    setCategory('General')
    setExternalId(null)
  }

  const setPackageFuntion = (id) => {
    var pack = packages.filter(function(item) { 
      console.log("iterar id", item.packageId, id )
      return item.packageId == id;
    });
    console.log("id", id, "pack", pack)
    setPackageEvent(pack[0])
    setPackageId(pack[0].packageId)

    setPeople(null)
    setPeopleId(0)
    setPackagePeopleId(null)
  }

  const setPeopleFunction = (quantity) => {
    var peopleItem = packageEvent.packagePeople.filter(function(item) { 
      return item.peopleId == quantity;
    });

    setPeople(peopleItem[0])
    setPeopleId(peopleItem[0].peopleId)
    setPackagePeopleId(peopleItem[0].packagePeopleId)

    onPriceChange( packageEvent.packagePeople, checkedValues, peopleItem[0].packagePeopleId, null, externalList, putIva, discountType, discount )
  }

  const onExtraChange = (e, element) => {
    const { name, value, checked, type } = e.target
    var array = checkedValues

    console.log("onExtraChange", checkedValues, name, type, checked, value)
    /*setState({
      ...state,
      [name]: type == 'checkbox' ? checked : (value ? parseInt(value) : null)
    });*/

    setState({
      ...state,
      [name]: value ? parseInt(value) : null
    });

    /*if (type == 'checkbox') {
      if(checked) {
        array.push({
          "itemId": element.itemId,
          "itemPrice": element.itemPrice,
          "quantity": type == 'number' ? parseInt(value) : 0
        })
      } else {
        var index = null;
        array.map( (item, i) => {
          if(item.itemId == element.itemId) index = i
        } )

        if(index != null) 
          array.splice(index, 1);
      }
    } else {
      var index = null;
      array.map( (item, i) => {
        if(item.itemId == element.itemId) index = i
      } )

      if( index != null ) array[index].quantity = value ? parseInt(value) : 0
    }*/

    var index = null;
    array.map( (item, i) => {
      if(item.itemId == element.itemId) index = i
    } )
    if( index != null ) array[index].quantity = value ? parseInt(value) : 0 
    else { 
      array.push(array.push({
        "itemId": element.itemId,
        "itemPrice": element.itemPrice,
        "quantity": value ? parseInt(value) : 0
      }))
    }

    console.log("array values", array)

    var count = 0;
    array.map( item => {
      if( item.quantity > 0 ) count++;
    })

    setCheckedValuesCount(count)
    setCheckedValues(array)
    onPriceChange( packageEvent.packagePeople, array, packagePeopleId, null, externalList, putIva, discountType, discount )
  }

  const onExtraChangeTemp = (e, inputType, obj) => {
    var array = checkedValues

    if(inputType == 'check') {
      if( !e.target.checked ) {
        var index = null;
        array.map( (item, i) => {
          if(item.itemId == obj.itemId) index = i
        } )

        if(index != null) {
          //delete array[ index ];
          array.splice(index, 1);
        }
      } else {
        array.push({
          "eventItemPackageId": null,
          "eventId": eventId,
          "itemId": obj.itemId,
          "itemName": obj.itemName,
          "itemPrice": obj.itemPrice,
          "quantity": obj.quantity,
          "editable": true
        })
      }
        //array.push(obj)
    } else {
      var index = null;
      array.map( (item, i) => {
        if(item.itemId == obj.itemId) index = i
      } )

      if( index != null ) array[index].quantity = parseInt(e.target.value)
    }

    console.log('array', array)
    setCheckedValues(array)
    onPriceChange( packageEvent.packagePeople, array, packagePeopleId, null, externalList, putIva, discountType, discount )
  }

  const onHolidayChange = (e) => {
    var holi = e.target.checked
    setHoliDay(holi)

    console.log("onHolidayChange", packagePeopleId)
    onPriceChange( packageEvent.packagePeople, checkedValues, packagePeopleId, holi, externalList, putIva, discountType, discount );
  }

  const onIVAChange = (e) => {
    var ivaVar = e.target.checked
    setPutIva(ivaVar)

    onPriceChange( packageEvent.packagePeople, checkedValues, packagePeopleId, holiDay, externalList, ivaVar, discountType, discount  );
  }

  const onDiscountTypeChange = (e) => {
    setDiscountType(e.target.value)

    onPriceChange( packageEvent.packagePeople, checkedValues, packagePeopleId, holiDay, externalList, putIva, e.target.value, discount );
  }

  const onDiscountChange = (e) => {
    setDiscount( parseFloat(e.target.value) )

    onPriceChange( packageEvent.packagePeople, checkedValues, packagePeopleId, holiDay, externalList, putIva, discountType, parseFloat(e.target.value) );
  }

  const onPriceChange = ( peopleCost, extras, peopleIdParam, holi, exList, putIvaVar, varDiscountType, varDiscount ) => {
    console.log("peopleCost", peopleCost, packagePeopleId)
    if(peopleCost) {
      var pricesResult = peopleCost.filter(obj => { return obj.packagePeopleId === peopleIdParam })
      console.log("pricesResult", pricesResult)
      var prices = pricesResult[0].prices
      var date  = new Date(daySelected)
      var day = date.getDay()
      var dayType = ""

      console.log("day", day, days)
      if(holi) dayType = 'friday'
      else {
        days.map( item => {
          item.weekDays.split(',').map( el => {
            if( parseInt(el) == parseInt(day)) dayType = item.day
          })
        })
      }

      /*if(holi) dayType = 'friday'
      else if(day >= 1 && day < 5) dayType = 'week'
      else if(day == 5) dayType = 'friday'
      else dayType = 'weekend'*/

      console.log("dayType:", dayType, peopleCost, extras)
      if( dayType == 'friday' ) setHoliDay(true)

      var result = prices.filter(obj => { return obj.day === dayType })

      console.log("final cost", result[0])
      
      setPackagePriceId(result[0].packagePriceId)
    }

    console.log("is extra", extras)
    var costExtra = 0;
    if(extras) {
      extras.map( item => {
        if(item.quantity) costExtra += (item.itemPrice * item.quantity)
      })
    }

    var externalCost = 0;
    exList.map( item => {
      if( item.price )
        externalCost += item.price
    })

    console.log("getting cost", parseFloat(result[0].price), parseFloat(costExtra))

    var ivaTemp = 0
    if( putIvaVar ) ivaTemp = (parseFloat(result[0].price) * 0.16)

    var total = parseFloat(result[0].price) + parseFloat(costExtra) + ivaTemp + externalCost

    if( varDiscountType && varDiscount && varDiscount > 0 ) {
      if( varDiscountType == 'percent' )
        varDiscount = ( parseFloat(result[0].price) * ( varDiscount / 100 ) ).toFixed(2);
    } else varDiscount = 0

    total = parseFloat(result[0].price) + parseFloat(costExtra) + ivaTemp + externalCost - varDiscount

    setCost( total )
    setPackageCost( parseFloat(result[0].price) )
    setExternalTotal( externalCost )
    setExtraCost( parseFloat( costExtra + externalCost ) )
    setIva( ivaTemp )
    setDiscountAmount( parseFloat(varDiscount) )
  }

  const handleSelectChange = (e) => {
    console.log("change", e)
    setHourEvent(e)
  }

  return (
    <>
      { !isMobile ? 
      <Tabs
        defaultActiveKey="step1"
        activeKey={key}
        className="mb-3"
        onSelect={(k) => onChangeTab(k)}
        fill
      >

      <Tab eventKey="step1" title="Evento">
      <Form>
        <Container>
          <Row>
            <div>
              <div className="section-title" align="center">{ action }</div>
              <br/>

              <Container>
                <Row>
                  <Col>
                    <Form.Group className='mb-3'>
                      <FloatingLabel controlId="floatingPassword" label="Nombre del evento">
                        <Form.Control required name="eventName" value={ eventName } placeholder="Nombre del evento" onChange={ (e) => setEventName(e.target.value) }/>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className='mb-3'>
                      <FloatingLabel controlId="floatingPassword" label="Fecha del Evento">
                        <Form.Control required type="date" name="eventDate" value={ eventDate } placeholder="Fecha del Evento" onChange={ (e) => setEventDate(e.target.value) }/>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className='mb-3'>
                      {/*<FloatingLabel controlId="floatingPassword" label="Hora del evento">
                        <Form.Select name="hourEvent" value={ hourEvent } onChange={ (e) => setHourEvent(e.target.value) }>
                          <option value="10">10:00 a 15:00 hrs</option>
                          <option value="16">16:00 a 21:00 hrs</option>
                          <option value="18">12:00 a 17:00 hrs</option>
                          <option value="18">12:00 a 17:00 hrs</option>
                        </Form.Select>
                      </FloatingLabel>*/}
                      
                      <Select
                        placeholder='Hora del evento'
                        label={ 'Option' }
                        value={ hourEvent }
                        onChange={ handleSelectChange }
                        options={ schedule }
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className='mb-3'>
                      <FloatingLabel controlId="floatingPassword" label="Nombre de contacto">
                        <Form.Control required name="clientName" value={ clientName } placeholder="Nombre de contacto" onChange={ (e) => setClientName(e.target.value) }/>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className='mb-3'>
                      <FloatingLabel controlId="floatingPassword" label="Número de contacto">
                        <Form.Control required name="clientPhoneNumber" value={ clientPhoneNumber } placeholder="Número de contacto" onChange={ (e) => setClientPhoneNumber(e.target.value) }/>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className='mb-3'>
                      <FloatingLabel controlId="floatingPassword" label="Email de contacto">
                        <Form.Control required type="email" name="clientEmail" value={ clientEmail } placeholder="Email de contacto" onChange={ (e) => setClientEmail(e.target.value) }/>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className='mb-3'>
                      <FloatingLabel controlId="floatingPassword" label="Dirección de contacto">
                        <Form.Control required type="text" name="clientAddress" value={ clientAddress } placeholder="Dirección de contacto" onChange={ (e) => setClientAddress(e.target.value) }/>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <FloatingLabel controlId="floatingPassword" label="Notas del Evento">
                        <Form.Control as="textarea" name="notes" value={ notes } placeholder="Notas del Evento" onChange={ (e) => setNotes(e.target.value) }/>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              </Container>

              <div align="right">
                <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowEventForm(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={ nextStep } type="button" className="grl-btn">{ 'SIGUIENTE' }</button>
              </div>
            </div>
          </Row>
        </Container>
      </Form>
      </Tab>

      <Tab eventKey="step2" title="Paquete">
        <div className="section-title" align="center">Configura tu paquete</div>
        <br/>
        
        <div align="center">
          <div className="section-path">Costo del evento</div>
          <div className="section-title-xl">{ CURRENCY_FORMAT.format( cost ) }</div>
          <Container>
            <Row>
              <Col><div className="section-path-light">Costo del paquete</div></Col>
              <Col> <div className="section-path-bold"><b>{ CURRENCY_FORMAT.format(packageCost) }</b></div> </Col>
            </Row>
            { putIva && 
              <Row>
                <Col><div className="section-path-light">IVA del paquete</div></Col>
                <Col> <div className="section-path-bold"><b>{ CURRENCY_FORMAT.format(iva) }</b></div> </Col>
              </Row>
            }
            <Row>
              <Col><div className="section-path-light">Costo de los extras</div></Col>
              <Col> <div className="section-path-bold"><b>{ CURRENCY_FORMAT.format(extraCost) }</b></div> </Col>
            </Row>

            { (discount && discount > 0) && 
              <Row>
                <Col><div className="section-path-light">Descuento</div></Col>
                <Col> <div className="section-path-bold"><b>{ CURRENCY_FORMAT.format(discountAmount) }</b></div> </Col>
              </Row>
            }

            <br/>
            <Row>
              <Col md={ 2 }></Col>
              <Col md={ 4 } align="left">
                <Form.Check 
                  type="switch"
                  label={ 'Día festivo' }
                  checked={ holiDay }
                  onChange={ (e) => onHolidayChange(e) }
                />
              </Col>
              <Col md={ 4 } align="left">
                <Form.Check 
                  type="switch"
                  label={ 'Agregar IVA' }
                  checked={ putIva }
                  onChange={ (e) => onIVAChange(e) }
                />
              </Col>
              <Col md={ 2 }></Col>
            </Row>      
          </Container>
        </div>

        <Form>
          <Container>
            <Row>
              <Col md="12">
                <Container>
                  <br/>
                  <Row>
                    <Col md={ 1 }></Col>
                    <Col md={ 5 }>
                      <Form.Group className='mb-3'>
                        <FloatingLabel label="Paquete">
                          <Form.Select name="packageEvent" value={ packageId } onChange={ (e) => setPackageFuntion(e.target.value) }>
                            <option>Selecciona tu paquete</option>
                            { packages.map(item => (
                              <option value={ item.packageId } >{ item.packageName }</option>
                            ))}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col md={ 5 }>
                      { packageEvent && 
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Personas">
                            <Form.Select name="people" value={ peopleId } onChange={ (e) => setPeopleFunction(e.target.value) }>
                              <option value={ 0 }>Selecciona la cantidad de personas</option>
                              { packageEvent.packagePeople.map(item => {
                                if( item.active)
                                  return <option value={ item.people.peopleId } >{ item.people.quantity }</option>
                              })}
                            </Form.Select>
                          </FloatingLabel>
                        </Form.Group>
                      }
                    </Col>
                    <Col md={ 1 }></Col>
                  </Row>
                </Container>
                
                { people &&
                <Accordion defaultActiveKey="0">
                  
                  <Accordion.Item eventKey="0">
                    <Accordion.Header><div className="section-subtitle" style={{ padding: '10px 0' }}> ¿Qué Incluye el paquete? </div></Accordion.Header>
                    <Accordion.Body>
                    
                      <Form.Group className='mb-3'>
                        <Container>
                          <Row>
                            <Col md={ 10 }><th>Elemento</th></Col>
                            <Col md={ 2 }><th>Cantidad</th></Col>
                          </Row>
                          <br/>
                          { people.items.map(item => (
                            <Row>
                              <Col md={ 10 }>
                                {/* <Form.Check 
                                  type="switch"
                                  label={ getItemEntity(item.packageItemsId.itemId) }
                                  checked
                                  disabled
                                /> */}
                                <div>{ getItemEntity(item.packageItemsId.itemId) }</div>
                              </Col>
                              
                              <Col md={ 2 }>
                                { item.quantity != -1 ? item.quantity : 'Ilimitado' }
                              </Col>
                            </Row>
                            
                          ))}
                        </Container>
                      </Form.Group>

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header><div className="section-subtitle" style={{ padding: '10px 0' }}> Descuento { discountAmount > 0 ? <span className="material-icons-outlined accordion-icon">check</span> : '' } </div></Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <Container>
                          <Row>
                            <Col md="6">
                              <Form.Group className='mb-3'>
                                <FloatingLabel controlId="floatingPassword" label="Tipo de descuento">
                                  <Form.Select name="discountType" value={ discountType } onChange={ (e) => onDiscountTypeChange(e) }>
                                    <option value="percent">Porcentaje</option>
                                    <option value="amount">Monto</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                            <Col md="6">
                              <Form.Group className='mb-3'>
                                <FloatingLabel label="Valor de descuento">
                                  <Form.Control type="number" name="discount" value={ discount } placeholder="Descuento" onChange={ (e) => onDiscountChange(e) }/>
                                </FloatingLabel>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header><div className="section-subtitle" style={{ padding: '10px 0' }}> Extras <span className='accordion-sub'>{ '(' + checkedValuesCount + ')' }</span> </div></Accordion.Header>
                    <Accordion.Body>
                      <Container>
                        <Row>
                          <Col md={ 6 }><th>Elemento</th></Col>
                          <Col md={ 3 }><th>Precio</th></Col>
                          <Col md={ 3 }><th>Cantidad</th></Col>
                        </Row>
                        <br/>
                        { pItems.map(item => (
                          <Row>
                            <Col md={ 6 }>
                              {/*<Form.Check 
                                type="switch"
                                label={ item.itemName }
                                name={ 'check' + item.itemId }
                                checked={ state[ 'check' + item.itemId ] }
                                onChange={ (e) => onExtraChange(e, item) }
                              />*/}
                              <div>{ item.itemName }</div>
                            </Col>

                            <Col md={ 3 }>
                              <div> { CURRENCY_FORMAT.format(item.itemPrice) } </div>
                            </Col>
                            
                            <Col md={ 3 } className="pb-2">
                              <Form.Control size="sm" type="number" name={ 'quantity_' + item.itemId } id={ 'quantity_' + item.itemId } value={ state[ 'quantity_' + item.itemId ] } placeholder="Cantidad" onChange={ (e) => onExtraChange(e, item) }/>
                              {/*<Form.Select size="sm" value={ item.quantity } onChange={ (e) => onExtraChange(e, 'select', item) }>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                              </Form.Select>*/}
                            </Col>
                          </Row>
                        ))}
                      </Container>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header><div className="section-subtitle" style={{ padding: '10px 0' }}> Notas Adicionales <span className='accordion-sub'>{ '(' + externalList.length + ')' }</span> </div></Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <Form>
                          <Container>
                            <Row>
                              <Col md="4">
                                <Form.Group className='mb-3'>
                                  <input type="hidden" name="id" value={ externalId } />
                                  <FloatingLabel controlId="floatingPassword" label="Categoría">
                                    <Form.Select name="hourEvent" value={ category } onChange={ (e) => setCategory(e.target.value) }>
                                      <option value="General">General</option>
                                      <option value="Comida">Comida</option>
                                      <option value="Show">Show</option>
                                      <option value="Piñata">Piñata</option>
                                      <option value="Pastel">Pastel</option>
                                    </Form.Select>
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col md="4">
                                <Form.Group className='mb-3'>
                                  <FloatingLabel label="Concepto">
                                    <Form.Control required type="text" name="name" value={ concept } placeholder="Concepto" onChange={ (e) => setConcept(e.target.value) }/>
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col md="4">
                                <Form.Group className='mb-3'>
                                  <FloatingLabel label="Precio">
                                    <Form.Control type="number" name="price" value={ extraPrice } placeholder="Notas" onChange={ (e) => setExtraPrice(e.target.value) }/>
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                              <Col md="12">
                                <Form.Group className='mb-3'>
                                  <FloatingLabel label="Notas">
                                    <Form.Control required as="textarea" name="comments" value={ extraNotes } placeholder="Notas" onChange={ (e) => setExtraNotes(e.target.value) }/>
                                  </FloatingLabel>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div align="right">
                                  <button type="button" className="grl-btn grl-btn-second" onClick={ () => cleanExtraForm(false) }>{ 'Limpiar' }</button>&nbsp;&nbsp;
                                  <button onClick={ updateExternal } type="button" className="grl-btn">{ 'Agregar' }</button>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Form>
                        <br/>

                        <div>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Categoría</th>
                                <th>Concepto</th>
                                <th>Precio</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              { externalList.length == 0 &&
                                <div>Sin Notas</div>
                              }
                              { externalList.map( item => (
                                <tr>
                                  <td> { item.category } </td>
                                  <td> { item.name } </td>
                                  <td> { CURRENCY_FORMAT.format(item.price) } </td>
                                  <td> 
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{ item.comments }</Tooltip>}>
                                      {({ ref, ...triggerHandler }) => (
                                        <Button variant="light" {...triggerHandler} className="btn-simple d-inline-flex align-items-center" >
                                          <InfoCircleFill ref={ref} className="table-icon"/>
                                        </Button>
                                      )}
                                    </OverlayTrigger>&nbsp;&nbsp;
                                    <PencilFill onClick={ () => editExternal(item) } className="table-icon" />&nbsp;&nbsp;
                                    <X className="table-icon" onClick={ () => removeExternal(item)} />
                                  </td>
                                </tr>
                              )) }
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
                }
              </Col>
              
              {/* <Col md={ 7 }>
                <Container>
                  <Row>
                    <Col md="12">
                      <div align="right">
                        <div className="section-path">Costo del evento</div>
                        <div className="section-title-xl">{ CURRENCY_FORMAT.format( cost ) }</div>
                        <Container>
                          <Row>
                            <Col><div className="section-path-light">Costo del paquete</div></Col>
                            <Col> <div className="section-path-light"><b>{ CURRENCY_FORMAT.format(packageCost) }</b></div> </Col>
                          </Row>
                          { putIva && 
                            <Row>
                              <Col><div className="section-path-light">IVA del paquete</div></Col>
                              <Col> <div className="section-path-light"><b>{ CURRENCY_FORMAT.format(iva) }</b></div> </Col>
                            </Row>
                          }
                          <Row>
                            <Col><div className="section-path-light">Costo de los extras</div></Col>
                            <Col> <div className="section-path-light"><b>{ CURRENCY_FORMAT.format(extraCost) }</b></div> </Col>
                          </Row>

                          { (discount && discount > 0) && 
                            <Row>
                              <Col><div className="section-path-light">Descuento</div></Col>
                              <Col> <div className="section-path-light"><b>{ CURRENCY_FORMAT.format(discountAmount) }</b></div> </Col>
                            </Row>
                          }
                        </Container>
                      </div>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col></Col>
                    <Col md={ 'auto' }>
                      <Form.Check 
                        type="switch"
                        label={ 'Día festivo' }
                        checked={ holiDay }
                        onChange={ (e) => onHolidayChange(e) }
                      />
                    </Col>
                    <Col md={ 'auto' }>
                      <Form.Check 
                        type="switch"
                        label={ 'Agregar IVA' }
                        checked={ putIva }
                        onChange={ (e) => onIVAChange(e) }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <br/>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><div className="section-subtitle" style={{ padding: '10px 0' }}> Descuento { discountAmount > 0 ? <span className="material-icons-outlined accordion-icon">check</span> : '' } </div></Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <Container>
                                <Row>
                                  <Col md="6">
                                    <Form.Group className='mb-3'>
                                      <FloatingLabel controlId="floatingPassword" label="Tipo de descuento">
                                        <Form.Select name="discountType" value={ discountType } onChange={ (e) => onDiscountTypeChange(e) }>
                                          <option value="percent">Porcentaje</option>
                                          <option value="amount">Monto</option>
                                        </Form.Select>
                                      </FloatingLabel>
                                    </Form.Group>
                                  </Col>
                                  <Col md="6">
                                    <Form.Group className='mb-3'>
                                      <FloatingLabel label="Valor de descuento">
                                        <Form.Control type="number" name="discount" value={ discount } placeholder="Descuento" onChange={ (e) => onDiscountChange(e) }/>
                                      </FloatingLabel>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header><div className="section-subtitle" style={{ padding: '10px 0' }}> Notas Adicionales <span className='accordion-sub'>{ '(' + externalList.length + ')' }</span> </div></Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <Form>
                                <Container>
                                  <Row>
                                    <Col md="4">
                                      <Form.Group className='mb-3'>
                                        <input type="hidden" name="id" value={ externalId } />
                                        <FloatingLabel controlId="floatingPassword" label="Categoría">
                                          <Form.Select name="hourEvent" value={ category } onChange={ (e) => setCategory(e.target.value) }>
                                            <option value="General">General</option>
                                            <option value="Comida">Comida</option>
                                            <option value="Show">Show</option>
                                            <option value="Piñata">Piñata</option>
                                            <option value="Pastel">Pastel</option>
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col md="4">
                                      <Form.Group className='mb-3'>
                                        <FloatingLabel label="Concepto">
                                          <Form.Control required type="text" name="name" value={ concept } placeholder="Concepto" onChange={ (e) => setConcept(e.target.value) }/>
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col md="4">
                                      <Form.Group className='mb-3'>
                                        <FloatingLabel label="Precio">
                                          <Form.Control type="number" name="price" value={ extraPrice } placeholder="Notas" onChange={ (e) => setExtraPrice(e.target.value) }/>
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                    <Col md="12">
                                      <Form.Group className='mb-3'>
                                        <FloatingLabel label="Notas">
                                          <Form.Control required as="textarea" name="comments" value={ extraNotes } placeholder="Notas" onChange={ (e) => setExtraNotes(e.target.value) }/>
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div align="right">
                                        <button type="button" className="grl-btn grl-btn-second" onClick={ () => cleanExtraForm(false) }>{ 'Limpiar' }</button>&nbsp;&nbsp;
                                        <button onClick={ updateExternal } type="button" className="grl-btn">{ 'Agregar' }</button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                              </Form>
                              <br/>

                              <div>
                                <Table responsive>
                                  <thead>
                                    <tr>
                                      <th>Categoría</th>
                                      <th>Concepto</th>
                                      <th>Precio</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    { externalList.length == 0 &&
                                      <div>Sin Notas</div>
                                    }
                                    { externalList.map( item => (
                                      <tr>
                                        <td> { item.category } </td>
                                        <td> { item.name } </td>
                                        <td> { CURRENCY_FORMAT.format(item.price) } </td>
                                        <td> 
                                          <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">{ item.comments }</Tooltip>}>
                                            {({ ref, ...triggerHandler }) => (
                                              <Button variant="light" {...triggerHandler} className="btn-simple d-inline-flex align-items-center" >
                                                <InfoCircleFill ref={ref} className="table-icon"/>
                                              </Button>
                                            )}
                                          </OverlayTrigger>&nbsp;&nbsp;
                                          <PencilFill onClick={ () => editExternal(item) } className="table-icon" />&nbsp;&nbsp;
                                          <X className="table-icon" onClick={ () => removeExternal(item)} />
                                        </td>
                                      </tr>
                                    )) }
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </Row>
                </Container>
              </Col> */}
            </Row>
            <Row>
              <div>
                <br/>
                <div align="right">
                  <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowEventForm(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
                  <button onClick={ nextStep } type="button" className="grl-btn">{ 'GUARDAR' }</button>
                </div>
              </div>
            </Row>
          </Container>
        </Form>
      </Tab>

      </Tabs>
      :
      <div>
        <div>
          <div align="center"> 
            <h1> <b>Eventos</b> </h1> 
            <div>{ DATE_FORMAT_SHORT.format((new Date( eventDateMobile )).getTime()) }</div>
          </div>

          <div>
            <br/>
            <Accordion>
              { dayEventsMobile.map( ( item, i ) => (
                <Accordion.Item eventKey={ i } key={ 'eventsM_' + i }>
                  <Accordion.Header>{ item.eventName }</Accordion.Header>
                  <Accordion.Body>
                    <div>{ 'Cliente: ' + item.clientName }</div>
                    <div>{ 'Balance: ' + (parseFloat(item.cost) - parseFloat(item.balance)) }</div>
                    <div>{ 'Paquete: ' +  item.packageName }</div>
                    <div>{ 'Personas: ' +  item.peopleNumber }</div>
                  </Accordion.Body>
                </Accordion.Item>
              )) }
            </Accordion>
          </div>
        </div>
        <br/>
        <div align="center"> 
          <h1> <b>Horarios disponibles</b> </h1> 
        </div>
        <br/>
        <Container>
          <Row>
            { schedule.map( item => {
              return <Col xs={ 6 }>
                <div><b> { item.label } </b></div>
                { item.options.map( hour => (
                  <div className={ hour.isDisabled ? 'disable-hour' : '' }>{ hour.label }</div>
                ))}
                <br/>
              </Col>
            }) }
          </Row>
        </Container>
        

        <div align="right">
          <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShowEventForm(false) }>{ 'CERRAR' }</button>
        </div>
      </div>
    }
    </>
  );
}

export default EventForm;
