import React, { useContext, useState, useEffect } from "react";
import axios from 'axios'
import GlobalContext from "../context/GlobalContext";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Modal from 'react-bootstrap/Modal'

const CANCEL_API = 'http://192.169.176.97/rest/api/events/cancelEvent/'
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hour: "numeric", minute: "numeric", hour12: false, timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

const labelsClasses = [
  "indigo",
  "gray",
  "green",
  "blue",
  "red",
  "purple",
];

export default function EventModal({ getEvents }) {
  const [ showConfirm, setShowConfirm ] = useState(false)

  const {
    setShowEventModal,
    setShowEventForm,
    daySelected,
    dispatchCalEvent,
    selectedEvent,
    setShowPayments,
    setShowContract,
  } = useContext(GlobalContext);

  const [title, setTitle] = useState(
    selectedEvent ? selectedEvent.eventName : ""
  );
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.notes : ""
  );
  const [balance, setBalance] = useState(
    selectedEvent ? selectedEvent.cost - selectedEvent.balance : 0
  );
  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((lbl) => lbl === selectedEvent.label)
      : labelsClasses[0]
  );

  useEffect(() => {
    if(!selectedEvent) {
      setShowEventForm(true)
      setShowEventModal(false)
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    const calendarEvent = {
      title,
      description,
      label: selectedLabel,
      day: daySelected.valueOf(),
      id: selectedEvent ? selectedEvent.id : Date.now(),
    };
    if (selectedEvent) {
      dispatchCalEvent({ type: "update", payload: calendarEvent });
    } else {
      dispatchCalEvent({ type: "push", payload: calendarEvent });
    }

    setShowEventModal(false);
  }

  const cancelEvent = () => {
    var id = selectedEvent.eventId

    axios({
      method: 'get',
      url: CANCEL_API + '/' + id,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("result cancel", result.data)
      dispatchCalEvent({
        type: "delete",
        payload: selectedEvent,
      });
      setShowEventModal(false);    
      getEvents()  
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  return (
    <>
      <Modal show={showConfirm} onHide={ () => setShowConfirm(false) }>
        <Modal.Body>
          <div className="message-modal" >¿Estás seguro de eliminar este evento?</div>
        </Modal.Body>
        <Modal.Footer>
          <span className="grl-btn" onClick={ () => setShowConfirm(false) } > Cancelar </span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="single-btn" onClick={ () => cancelEvent() } > Aceptar </span>
        </Modal.Footer>
      </Modal>

      <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center" style={{ zIndex: 41 }}>
        <form className="bg-white rounded-lg shadow-2xl w-1/4" style={{ width: '30%' }}>
          <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
            <span className="material-icons-outlined text-gray-400">
              drag_handle
            </span>
            <span>&nbsp;</span>
            <div style={ { paddingTop: '5px' } }>
              {selectedEvent && 
                <>
                  <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                    <span  onClick={ () => setShowEventForm(true) } className="material-icons-outlined text-gray-400 cursor-pointer">
                      edit
                    </span>
                  </OverlayTrigger>&nbsp;&nbsp;&nbsp;

                  <OverlayTrigger overlay={<Tooltip>Pagos</Tooltip>}>
                    <span onClick={ () => setShowPayments(true) } className="material-icons-outlined text-gray-400 cursor-pointer">
                      payments
                    </span>
                  </OverlayTrigger>&nbsp;&nbsp;&nbsp;

                  <OverlayTrigger overlay={<Tooltip>Contrato</Tooltip>}>
                    <span onClick={ () => setShowContract(true) } className="material-icons-outlined text-gray-400 cursor-pointer">
                      description
                    </span>
                  </OverlayTrigger>&nbsp;&nbsp;&nbsp;

                  <OverlayTrigger overlay={<Tooltip>Borrar</Tooltip>}>
                    <span onClick={ () => setShowConfirm(true) } className="material-icons-outlined text-gray-400 cursor-pointer">
                      delete
                    </span>
                  </OverlayTrigger>&nbsp;&nbsp;&nbsp;
                </>
              }

              <OverlayTrigger overlay={<Tooltip>Cerrar</Tooltip>}>
                <span className="material-icons-outlined text-gray-400 cursor-pointer" onClick={() => setShowEventModal(false)}>
                  close
                </span>
              </OverlayTrigger>
            </div>
            {/* <div>
              {selectedEvent && (
                <span
                  onClick={() => {
                    dispatchCalEvent({
                      type: "delete",
                      payload: selectedEvent,
                    });
                    setShowEventModal(false);
                  }}
                  className="material-icons-outlined text-gray-400 cursor-pointer"
                >
                  delete
                </span>
              )}
              <button onClick={() => setShowEventModal(false)}>
                <span className="material-icons-outlined text-gray-400">
                  close
                </span>
              </button>
            </div>*/}
          </header>
          <div className="p-3">
            <div className="items-end gap-y-7">
              <div></div>
              {/*<input
                type="text"
                name="title"
                placeholder="Add title"
                value={title}
                required
                className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                onChange={(e) => setTitle(e.target.value)}
                />*/}
              <div className="event-text">
                {title}
              </div>
              <div>{ DATE_FORMAT.format( selectedEvent ? (new Date(selectedEvent.eventDate)).getTime() : daySelected )}</div>
              { balance > 0 &&
                <>
                  <div className="notes-class">Balance:</div>
                  <div><span className="item-event-red item-event-color"> { CURRENCY_FORMAT.format( balance ) } </span></div>
                </>
              }
              <br/>
              <div className="notes-class">Notas:</div>
              <div>{ description != null ? description : "Sin notas" }</div>
              
              
              {/*<span className="material-icons-outlined text-gray-400">
                bookmark_border
              </span>
              <div className="flex gap-x-2">
                {labelsClasses.map((lblClass, i) => (
                  <span
                    key={i}
                    onClick={() => setSelectedLabel(lblClass)}
                    className={`bg-${lblClass}-500 w-6 h-6 rounded-full flex items-center justify-center cursor-pointer`}
                  >
                    {selectedLabel === lblClass && (
                      <span className="material-icons-outlined text-white text-sm">
                        check
                      </span>
                    )}
                  </span>
                ))}
              </div>*/}
              <br/>
            </div>
          </div>
          {/* <footer className="flex justify-end border-t p-3 mt-5">
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
            >
              Cerrar
            </button>  
          </footer> */}
        </form>
      </div>
    </>
  );
}
