import React, { useState, useEffect, useContext } from "react";
import axios from 'axios'
import { isMobile } from "react-device-detect"

import PaymentForm from "./PaymentForm";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'

import './App.css';
import { Wallet2, X } from "react-bootstrap-icons";

import GlobalContext from "./context/GlobalContext";

const CHARGE_API = 'http://192.169.176.97/rest/api/payments/getExpensesMonth'
const UPDATE_API = 'http://192.169.176.97/rest/api/payments/addExpense'
const CANCEL_API = 'http://192.169.176.97/rest/api/payments/cancelExpense/'
const TYPES_API = 'http://192.169.176.97/rest/api/payments/getPaymentTypes'
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
//const DATE_FORMAT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hour: "numeric", minute: "numeric", hour12: false, timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { year: 'numeric', month: 'short', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

const paymentStatus = {
  "1": "Pendiente",
  "2": "Pagado",
  "3": "Cancelado",
  "4": "Reembolsado",
  "5": "Adelanto",
}

function Charges({ setGlobalLoading }) {
  const [ charges, setCharges ] = useState([])
  const [ show, setShow ] = useState(false)
  const [ showChargesForm, setShowChargesForm ] = useState(false)
  const [ dateApplication, setDateApplication ] = useState(null)

  const [ expenseId, setExpenseId ] = useState(null)
  const [ expenseName, setExpenseName ] = useState("")
  const [ expenseType, setExpenseType ] = useState("Servicio")
  const [ expenseAmount, setExpenseAmount ] = useState(0)

  const [ expenseDate, setExpenseDate ] = useState(new Date())
  //const [ expenseTypeOutId, setExpenseTypeOutId ] = useState(null)
  const [ cancelExpenseDate, setCancelExpenseDate ] = useState(null)
  const [ userCancelExpense, setUserCancelExpense ] = useState(null)
  const [ statusExpenseId, setStatusExpenseId ] = useState(null)
  const [ paymentTypes, setPaymentTypes ] = useState([])

  const { setTotalCharge, setCountCharge } = useContext(GlobalContext);

  useEffect(() => {
    getCharges()
    getTypes()
  }, []);

  const getCharges = () => {
    setGlobalLoading(true)

    axios({
      method: 'get',
      url: CHARGE_API,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("charges", result.data)
      var chargesList = result.data
      var total = 0;
      var count = 0;

      chargesList.map(item => {
        if( item.paymentStatusId != 3 ) {
          total += item.amount;
          count ++;
        }
      })

      setCharges(chargesList)
      setCountCharge(count)
      setTotalCharge(total)
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const showChargeForm = (item) => {
    let date  = new Date()
    let dateP =  date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())

    setExpenseId(null)
    setExpenseName(null)
    setExpenseType(1)
    setExpenseAmount(0)
    setExpenseDate(new Date())
    //setExpenseTypeOutId(null)
    setCancelExpenseDate(null)
    setUserCancelExpense(null)
    setStatusExpenseId(2)
    setDateApplication(null)
      
    setShow(true)
  }

  const updateItem = (e) => {
    e.preventDefault();
    setGlobalLoading(true)

    let date = new Date().getTime()
    var applyDate = new Date( dateApplication );
    applyDate.setTime(applyDate.getTime() + applyDate.getTimezoneOffset() * 60 * 1000);
    applyDate.setSeconds(applyDate.getSeconds() + 60);    

    let json = {
      "expenseId": expenseId,
      "expenseName": expenseName,
      "paymentTypeId": expenseType,
      "amount": parseFloat(expenseAmount),
      "dateApplication": applyDate,
      "expenseDate": date,
      "cancelExpenseDate": cancelExpenseDate,
      "paymentStatusId": statusExpenseId,
    }

    console.log("update charge", json)

    axios({
      method: 'post',
      url: UPDATE_API,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
      data: json
    })
    .then(result => {
      console.log("result update", result.data)
      setShow(false)
      getCharges()
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setShow(false)
      setGlobalLoading(false)
    })
  }

  const cancelExpense = (id) => {
    setGlobalLoading(true)

    axios({
      method: 'get',
      url: CANCEL_API + id,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("result cancel", result.data)
      getCharges()
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })
  }

  const getTypes = () => {
    axios({
      method: 'get',
      url: TYPES_API,
      //headers: { 'content-type': 'application/json' },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("events on payment", result.data)
      setPaymentTypes( result.data )
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  return (
    <>

      <Modal show={showChargesForm} onHide={ () => setShowChargesForm(false) }>
        <Modal.Body>
        </Modal.Body>
      </Modal>

      <Modal show={show}>
        <Modal.Body>
          <Form onSubmit={ updateItem }>
            <Container>
              <Row>
                <div>
                  <div className="section-title">Crea tu gasto</div>
                  <br/>

                  <Container>
                    <Row>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel label="Fecha de aplicación">
                            <Form.Control required type="date" max={new Date().toISOString().split("T")[0]} name="dateApplication" value={ dateApplication } placeholder="" onChange={ (e) => setDateApplication(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel controlId="floatingPassword" label="Pago">
                            <Form.Control required type="number" name="expenseAmount" value={ expenseAmount } placeholder="" onChange={ (e) => setExpenseAmount(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel controlId="floatingPassword" label="Concepto">
                            <Form.Control required name="expenseName" value={ expenseName } placeholder="" onChange={ (e) => setExpenseName(e.target.value) }/>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className='mb-3'>
                          <FloatingLabel controlId="floatingPassword" label="Tipo de pago">
                            <Form.Select name="expenseType" value={ expenseType } onChange={ (e) => setExpenseType(e.target.value) }>
                              { paymentTypes.map( item => (
                                <option value={ item.paymentTypeId }>{ item.name }</option>
                              ))}
                            </Form.Select>
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      
                    </Row>
                  </Container>
                    <div align="right">
                      <button type="button" className="grl-btn grl-btn-second" onClick={ () => setShow(false) }>{ 'CANCELAR' }</button>&nbsp;&nbsp;
                      <button type="submit" className="grl-btn">{ 'ENVIAR' }</button>
                    </div>
                </div>
              </Row>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>

      <Container style={{ padding: 0 }}>
        <Row>
          {/* <Col md={ 6 }>
            <Container className='main-component card-violet'>
              <Row>
                <Col md={ 12 }>
                  <div className="section-subtitle" style={{ color: '#fff', fontSize: '40px', lineHeight: '50px' }}> Gastos del periodo </div>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <div>Estos son los gastos que se registraron en el sistema durante todo el mes corriente. </div> <br/>
                  <div><b>Importante:</b> No sera posible cancelar gastos de meses anteriores al actual</div>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col md={ 6 }>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Gastos</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ countCharge }</div>
                </Col>
                <Col md={ 6 }>
                  <div className="small-title" style={{ color: '#fff', fontWeight: 400 }}>Egresos</div>
                  <div className="medium-title" style={{ color: '#fff' }}>{ CURRENCY_FORMAT.format( totalCharge ) }</div>
                </Col>
              </Row>
            </Container>
          </Col> */}
          
          <Col md={ 12 }>
            <Container className='main-component table-style' style={{ padding: 0 }}>
              <Row className="align-items-center">
                <Col md={ 9 }>
                  <div className="section-subtitle"> Listado de Egresos </div>
                </Col>
                { !isMobile &&
                  <Col md={ 3 } align="right">
                    <div className="single-btn" onClick={ () => showChargeForm() } > Nuevo </div>
                  </Col>
                }
              </Row>
              <br/>
              <Row>
                <Col>
                  <Table responsive className="table-scroll">
                    <thead>
                      <tr>
                        <th>Concepto</th>
                        <th>Monto</th>
                        <th>Estatus</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      { charges.map( item => (
                        <tr>
                          <td> 
                            <div className="sub-date">{ DATE_FORMAT.format((new Date(item.dateApplication)).getTime()) } </div>
                            <div> { item.expenseName } </div>
                          </td>                            
                          <td> { CURRENCY_FORMAT.format(item.amount) } </td>
                          {/* <td> { paymentTypes.find((element) => element.paymentTypeId == item.paymentTypeId) ? (paymentTypes.find((element) => element.paymentTypeId == item.paymentTypeId)).name : '' } </td> */}
                          <td>
                            <div align="center"> 
                              <span className={ item.paymentStatusId == 2 || item.paymentStatusId == 5 ? 'status-ok-icon' : 'status-cancel-icon' }> { paymentStatus[item.paymentStatusId.toString()] } </span>
                            </div>
                          </td>
                          <td> 
                            { (!isMobile && item.paymentStatusId != 3) && 
                              <> <X onClick={ () => cancelExpense(item.expenseId) } className="table-icon" /> </>
                            }
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default Charges;
