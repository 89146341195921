import React, { useState, useEffect } from "react";
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'

import './App.css';
import { ArrowLeft } from "react-bootstrap-icons";

const UPDATE_ITEMS_API = 'http://192.169.176.97/rest/api/paquetes/updatePackageItems'
const ITEMS_API = 'http://192.169.176.97/rest/api/paquetes/items'
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hour: "numeric", minute: "numeric", hour12: false, timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

const dias = { "week": 'Entre semana', "friday": 'Viernes o día festivo', "weekend": 'Fin de semana' }

function PackagesItems( { closeEditItems, editItem, setGlobalLoading } ) {
  const [ state, setState ] = useState({})
  const [ items, setItems ] = useState([])
  const [ checkedValues, setCheckedValues ] = useState([])

  useEffect(() => {
    var activeItems = {}

    editItem.items.map( item => {
      activeItems['check' + item.packageItemsId.itemId] = true
      activeItems['quantity_' + item.packageItemsId.itemId] = item.quantity
    })

    setState(activeItems);

    console.log('activeItems', activeItems)
    getItems()
  }, []);

  const getItems = () => {
    axios({
      method: 'get',
      url: ITEMS_API,
      headers: { 'content-type': 'application/json' },
    })
    .then(result => {
      console.log("Items", result.data)
      var rest = result.data

      setItems(rest)
    })
  }

  const onExtraChange = (e) => {
    const { name, value, checked, type } = e.target;

    console.log("onExtraChange", name, type, value)
    setState({
      ...state,
      [name]: type == 'checkbox' ? checked : parseInt(value)
    });
  }

  const updatePrices = (e) => {
    e.preventDefault();
    setGlobalLoading(true)

    var json = {
      'packagePeopleId': editItem.packagePeopleId,
      'items': []
    }

    var checkboxes = document.querySelectorAll('input:checked');
    var values = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked == true) {
        values.push(checkboxes[i].name);
      }
    }

    values.map( item => {
      json.items.push( item + ':' + document.getElementById( 'quantity_' + item ).value  )
    })
    
    console.log("final JSON", json)
    axios({
      method: 'post',
      url: UPDATE_ITEMS_API,
      headers: { 'content-type': 'application/json' },
      data: json
    })
    .then(result => {
      console.log("result save packageItems", result.data)
      closeEditItems()
      setGlobalLoading(false)
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading(false)
    })

    /*var form = document.getElementById('itemsForm');
		var formData = new FormData(form);
    axios.post(UPDATE_ITEMS_API, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
    }).then(result => {
      console.log("result update", result.data)
      closeEditItems()
    })
    .catch(error => {
      console.log("error", error.message)
    })*/
  }

  const handleOnPriceChange = (e) => {    
    const { name, value, checked, type } = e.target;
    const newVal = value;

    setState({
      ...state,
      [name]: newVal
    });
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={ 12 }>
            <Form id="itemsForm" onSubmit={ updatePrices }>
              <input type="hidden" name="packagePeopleId" value={ editItem ? editItem.packagePeopleId : '' } />
              <Container>
                <Row>
                  <Col>
                    <div className="section-title">
                      <ArrowLeft onClick={ () => closeEditItems() } className="table-icon" />&nbsp;&nbsp;
                      <span className="nav-title">Edita elementos del paquete</span>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col md={ 12 } className="mb-4">
                    <Container className='main-component'>
                      <Row>
                        <Col md={ 9 }>
                          <div className="section-subtitle"> Personas </div>
                          <div className="section-title"> { editItem ? editItem.people.quantity : 0 } </div>
                        </Col>
                      </Row>
                      <br/>
                      <Row>
                        <Col md={ 12 }>
                          { items.map( item => (
                              <Container style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                                <Row>
                                  <Col md={ 7 }>
                                    <Form.Check 
                                      type="switch"
                                      label={ item.itemName }
                                      name={ item.itemId }
                                      checked={ state[ 'check' + item.itemId ] }
                                      onChange={ (e) => onExtraChange(e) }
                                    />
                                  </Col>
                                  <Col md={ 2 }>
                                    <div> { CURRENCY_FORMAT.format(item.itemPrice) } </div>
                                  </Col>
                                  <Col md={ 3 }>
                                    <Form.Control size="sm" type="number" name={ 'quantity_' + item.itemId } id={ 'quantity_' + item.itemId } value={ state[ 'quantity_' + item.itemId ] } placeholder="Cantidad" onChange={ (e) => onExtraChange(e) }/>
                                  </Col>
                                </Row>
                              </Container>
                          )) }
                        </Col>
                      </Row>
                      
                    </Container>
                  </Col>
                </Row>
                <Row>
                  <div align="right">
                    <button type="submit" className="grl-btn"> Guardar </button>
                  </div>
                </Row>
              </Container>
            </Form>
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default PackagesItems;
