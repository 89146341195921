import React, { useContext, useState, useEffect, useRef } from "react";
import axios from 'axios'
import { useReactToPrint } from 'react-to-print';
import GlobalContext from "./context/GlobalContext";
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";

import { Col, Container, Row } from "react-bootstrap";

import logo from './img/logo_c.png'

const PACKAGES_API = 'http://192.169.176.97/rest/api/paquetes/packages'

export default function Contract({ setGlobalLoading }) {
  const [ packages, setPackages ] = useState( { people: { quantity: 0 } } )
  const [ date, setDate ] = useState( [] )

  const componentRef = useRef();
  
  const owner = 'Alcara Digital, SA de CV'
  const store = 'Clavería'
  const rfc = 'ADI191016QN9'
  const address = 'Loma Verde no.81, Col San Juan Ixtacala, Atizapán de Zaragoza'
  const storeAddress = 'Egipto 185, Col. Claveria, Azcapotzalco'
  const tel = '56 1176 6059'
  const clean = '1,000'

  const {
    selectedEvent,
    setShowContract
  } = useContext(GlobalContext);

  useEffect(() => {
    console.log("selected", selectedEvent)
    getPackages()
    setDate( new Date(selectedEvent.eventDate) )
  }, []);

  const getPackages = () => {
    setGlobalLoading( true )
    axios({
      method: 'get',
      url: PACKAGES_API,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      //var tempPackageEvent = null
      var temp = null
      result.data.map( pack => {
        pack.packagePeople.map( item => {
          if( item.packagePeopleId == selectedEvent.packagePeopleId) {
            temp = item;
            //tempPackageEvent = pack;
          }
        })
      })
      
      setPackages( temp )
      setGlobalLoading( false )
    })
    .catch(error => {
      console.log("error", error.message)
      setGlobalLoading( false )
    })
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div>
        <div ref={componentRef} className="print-ref-contrat"> 
          <div className="container_c" >
            <div> <img src={ logo } className='contract-img' /> </div>
            <br/>
            <div> CONTRATO DE PRESTACIÓN DE SERVICIOS DE EVENTOS SOCIALES QUE CELEBRAN POR UNA PARTE { owner } PROPIETARIA DEL SALÓN DE FIESTAS SPLASH FUN SUC. { store } A QUIÉN EN LO SUCESIVO SE LE DENOMINARÁ <b>"EL PRESTADOR DE SERVICIO"</b> Y POR OTRA PARTE <b>{ selectedEvent.clientName }</b> A QUIÉN EN LO SUCESIVO SE EL DENOMINARÁ <b>"EL CONSUMIDOR"</b> AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLAUSULAS. </div>
            <br/>
            <div align="center"> DECLARACIONES </div>
            <br/>
            <div> 1. Declara el <b>PRESTADOR DE SERVICIO</b>: </div>
            <div> a) Ser una persona moral con capacidad legal para presentar el presente contrato. </div>
            <div> b) Que se encuentra en el Registro Federal de Contribuyentes bajo el RFC: { rfc }. </div> 
            <div> c) Que cuenta con la capacidad, infraestructura, servicios y recursos necesarios para dar cabal cumplimiento a las obligaciones que por virtud del presente contrato adquiere. </div>
            <div> d) Que para los efectos legales del presente contrato señala como su domicilio en: { address }. </div>
            <div> e) <b>Horario de Atención Martes a Domingo de 12:00 a 18:00 hrs Teléfono: { tel }. </b></div>
            <br/>
            <div> 2. Declara el <b>CONSUMIDOR</b>: </div>
            <div> a) Llamarse como ha quedado plasmado en el promedio de este contrato. </div>
            <div> b) Que su deseo es obligarse en los términos y condiciones del presente contrato, manifestandose que cuenta con la capacidad legal para la celebración de este acto. </div>
            <div> c) Que su número teléfonico es <b>{ selectedEvent.clientPhoneNumber }.</b> </div>
            <div> d) Que para los efectos legales del presente contrato señala como su domicilio el ubicado en: { selectedEvent.clientAddress }. </div>
            <br/>
            <div align="center"> CLAUSULAS </div>
            <br/>
            <div> <b>PRIMERA.-</b> El objeto del presente contrato es la prestación de servicios para la organización de un evento social para <b>{ packages.people.quantity }</b> personas, el cual se llevará acabo el día <b>{ dayjs( date ).format("DD") } del mes { dayjs( date ).locale("es").format("MMMM") } de { dayjs( date ).format("YYYY") }</b> y tendrá una duración de <b>{ 5 }</b> horas, de acuerdo a las características y especificaciones de la carátula de este contrato. </div>
            <div> <b>SEGUNDA.-</b> El evento social se llevará a cabo el dia <b>{ dayjs( date ).format("DD") } del mes de { dayjs( date ).locale("es").format("MMMM") } de { dayjs( date ).format("YYYY") }</b>, el cual iniciará a las <b>{ dayjs( date ).format("HH") }</b> horas y terminará a las <b>{ (parseInt(dayjs( date ).format("HH")) + 5 ) }</b> horas y tendrá verificativo en el SALON DE FIESTAS SPLASH FUN Suc. <b>{ store }</b>, ubicado <b>{ storeAddress }</b> </div>
            <div> Dentro de la ubicación del evento no se cuenta el tiempo necesario que el <b>PRESTADOR DE SERVICIO</b> requiera para la organización del mismo. <b>EL PRESTADOR DE SERVICIO</b> podrá cobrar una cantidad adicional, debidamente prevista en el presupuesto, en el caso que el evento prolongue su duración y/o el número de invitados exceda del estipulado. </div>
            <div> <b>TERCERA.-</b> El costo total que <b>EL CONSUMIDOR</b> debe solventar por la prestación del servicio es el estipulado en la carátula del presente contrato, no importando si el número de asistentes al evento es inferior al estipulado. Dicho costo será cubiertos por <b>EL CONSUMIDOR</b> de contado, en Moneda Nacional y en la forma siguiente: </div>
            <div style={{ paddingLeft: '20px' }} > a) $2,000 M.N. a la firma del presente contrato, por concepto de anticipo. </div>
            <div style={{ paddingLeft: '20px' }} > b) El restante { 15 } días antes de la celebración del evento. </div>
            <div> En caso de que <b>EL CONSUMIDOR</b> efectúe un anticipo por los servicios contratados, <b>EL PRESTADOR DEL SERVICIO</b> deberá expedir el comprobante respectivo el que contendrá por lo menos a la siguiente información: Nombre, Razón Social, Fecha e importe de anticipo, nombre y firma de la persona debidamente autorizada que recibe el anticipo, el nombre de <b>EL CONSUMIDOR</b>, la fecha, hora y tipo de evento. </div>
            <div> <b>EL CONSUMIDOR</b> se obliga depositar una cantidad equivalente al 10% del costo estipulado para garantizar el pago de servicios excedentes, imprevistos, o daños y perjuicios en su caso. Dicho depósito será devuelto a <b>EL CONSUMIDOR</b> si al finalizar el evento no se verificó ninguno de estos supuestos. Independientemente de la entrega o no del anticipo, <b>EL PRESTADOR DEL SERVICIO</b> deberá entregar a el <b>CONSUMIDOR</b> la factura o comprobante que ampare el pago de los servicios contratados, en la que se hará constar detalladamente el nombre y precio de cada uno de los servicios proporcionados, esto con la finalidad de que <b>EL CONSUMIDOR</b> pueda verificarlos en detalle. </div>
            <div> <b>CUARTA.-</b> A efecto de tener seguridad en cuanto al número de asistentes al evento social, <b>EL CONSUMIDOR</b> y <b>EL PRESTADOR DEL SERVICIO</b> establecen como procedimiento de control y verificación, el siguiente: </div>
            <div> a) <b>EL CONSUMIDOR</b> y <b>EL PRESTADOR DEL SERVICIO</b> designarán, cada uno, a una persona de su confianza a efecto de que sólo ingresan al lugar personas autorizadas por <b>EL CONSUMIDOR</b> para lo cual, podrán pactar el uso de boleto y contrasena. </div>
            <div> b) <b>EL CONSUMIDOR</b> se responsabiliza del excedente de personas que con su autorización expresa hayan ingresado al evento. </div>
            <div> <b>QUINTA.-</b> <b>EL CONSUMIDOR</b> cuenta con un plazo de cinco días hábiles posteriores a la firma del presente contrato para cancelar la operación sin responsabilidad alguna de su parte, en cuyo caso <b>EL PRESTADOR DEL SERVICIO</b> se obliga a reintegrar todas las cantidades que <b>EL CONSUMIDOR</b>el haya entregado, lo anterior no será aplicable si la fecha de la prestación del servicio se encuentra a diez días hábiles o menos de la celebración. </div>
            <div> <b>SEXTA.-</b> <b>EL CONSUMIDOR</b> se obliga a designar a una persona de su confianza, quien durante el evento será quien trate los asuntos relacionados con la prestación del servicio, asimismo se obliga a abstenerse de dar instrucciones al personal <b>del PRESTADOR DEL SERVICIO</b> que no tenga relación con el objeto del presente ya procurar que sus invitados observen la misma conducta. Por su parte <b>EL PRESTADOR DEL SERVICIO</b> se obliga adesignar, de entre su personal, a una persona que será quien durante la celebración del evento trate con el representante de <b>EL CONSUMIDOR</b> o con el mismo, los asuntos relacionados con la prestación del servicio, y se obliga a que su personal atienda con esmero y cortesía a los asistentes del evento. </div>
            <div> <b>SEPTIMA.-</b> En su caso <b>EL CONSUMIDOR</b> se obliga a cumplir con las disposiciones reglamentarias que rijan el inmueble ya procurar que los asistentes al evento observen la misma conducta. Para tal efecto <b>EL PRESTADOR DEL SERVICIO</b> deberá entregar a <b>EL CONSUMIDOR</b> una copia del reglamento respectivo y fijar en su lugar visible sus disposiciones. </div>
            <div style={{ paddingLeft: '20px' }}> a) <b>EL CONSUMIDOR</b> y sus invitados se responsabilizan del cuidado de los niños y adultos durante el evento por lo que se libera de toda responsabildad a <b>EL PRESTADOR DEL SERVICIO</b> en caso de que se presente algún accidente por el uso de juegos y/o de las instalaciones, no imputables a <b>EL PRESTADOR DEL SERVICIO</b>. </div>
            <div style={{ paddingLeft: '20px' }}> b) <b>EL CONSUMIDOR</b> y <b>EL PRESTADOR DEL SERVICIO</b> están de acuerdo en las siguientes restricciones: </div>
            <br/>
            <div style={{ paddingLeft: '20px' }}> No se permiten introducir bebidas alcohólicas. </div>
            <div style={{ paddingLeft: '20px' }}> No se permite el uso de confeti, serpentinas, ni espuma en spray. </div>
            <div style={{ paddingLeft: '20px' }}> No se permite jugar pelota o usar patines dentro del salón. </div>
            <div style={{ paddingLeft: '20px' }}> No es permite entrar con comida o refresco al área de juegos. </div>
            <div> <b>NOTA: En caso de que dentro del evento se utilice confeti, serpentinas, o espumas en spray, se hará un cargo de ${ clean } M.N. por concepto de limpieza. </b></div>
            <div> <b>OCTAVA.-</b> En caso de que <b>EL PRESTADOR DEL SERVICIO</b> incurra en incumplimiento del presente contrato por causas imputables a él se obliga aelección de <b>EL CONSUMIDOR</b>: </div>
            <div style={{ paddingLeft: '20px' }} > a) A contratar por su cuenta a otra empresa que este en posibilidades de realizar la prestación del servicio en las condiciones estipuladas por <b>EL CONSUMIDOR</b>. El costo adicional, que en su caso, se genere será absorbido por <b>EL PRESTADOR DEL SERVICIO</b>, o bien </div>
            <div style={{ paddingLeft: '20px' }} > b) A reintegrar las cantidades que se le hubieran entregado y a pagar una pena convenciónal del 20% del costo total del servicio. </div>
            <div> <b>NOVENA.-</b> <b>EL PRESTADOR DEL SERVICIO</b> es responsable ante <b>EL CONSUMIDOR</b> por el incumplimiento de los servicios contratados, aun cuando se subcontrate con terceros dicha prestación. </div>
            <div> <b>DÉCIMA.-</b> El servicio resguardado de objetos personales, será gratuito para los asistentes al evento, <b>EL PRESTADOR DEL SERVICIO</b> se obliga a recibir dichos objetos o bienes y entregarlos cuando los asistentes se los soliciten previa entrega de la contraseña correspondiente. </div>
            <div> <b>EL PRESTADOR DEL SERIVICIO</b> responderá por la pérdida, menos cabo o daño que por su malicia o negligencia sufrieren las cosas depositadas. En estos casos se indemnizará a <b>EL CONSUMIDOR</b> o a sus invitados, de la manera siguiente: </div>
            <div style={{ paddingLeft: '20px' }} > a) En caso de daño parcial, hasta con un 50% del valor del objeto que su dueño pueda demostrar fehacientemente o que las partes establezcan de común acuerdo; </div>
            <div style={{ paddingLeft: '20px' }} > b) En caso de daño total que hiciere inservible el bien objeto del depósito, <b>EL PRESTADOR DEL SERVICIO</b> se obliga a entregar a su dueño un bien de la misma especie y calidad o a indemnizarlo con un 100% del valor del objeto que su dueño pueda demostrar fehacientemente, o conforme al monto que las partes de común acuerdo establezcan; </div>
            <div style={{ paddingLeft: '20px' }} > c) En caso de robo o pérdida del bien objeto del depósito, <b>EL PRESTADOR DEL SERVICIO</b> indemnizará a su dueño con una cantidad equivalente al 100% del valor del bien de que trate o conforme al monto que las partes de común acuerdo establezcan. </div>
            <div> <b>DECIMA PRIMERA.-</b> Si los bienes destinados a la prestación del servicio sufrieren un menoscabo por culpa o negligencia debidamente comprobada, <b>DEL CONSUMIDOR</b> o de sus invitados, éste se obliga a cubrir los gastos de reparación de los mismos, o en su caso, indemnizar a <b>el prestador del servicio</b> hasta con un 60% de su valor. </div>
            <div> <b>DÉCIMA SEGUNDA.-</b> Las partes podrán acordar que <b>EL CONSUMIDOR</b> contrate libremente servicios específicos relacionados con el evento social con otros prestadores de servicios por así convenir a sus intereses, por lo que en caso de actualizarse dicho supuesto <b>EL CONSUMIDOR</b> exime de toda responsabilidad a<b>EL PRESTADOR DEL SERVICIO</b> en lo que respecta adichos servicios en específico. </div>
            <div> <b>DÉCIMA TERCERA.-</b> En caso de que <b>el PRESTADOR DEL SERVICIO</b> se encuentre imposibilitado para prestar el servicio por caso fortuito o fuerza mayor, incendio, temblor u otros acontecimientos de la naturaleza o hechos del hombre ajenos a la voluntad <b>del PRESTADOR DEL SERVICIO</b>, no se incurrirá en incumplimiento, por lo que no habrá pena convencional en dichos supuestos, debiendo únicamente <b>EL PRESTADOR DEL SERVICIO</b> reintegrar a <b>EL CONSUMIDOR</b> el anticipo que el hubiera entregado. </div>
            <div> <b>DÉCIMA CUARTA.-</b> Si <b>EL CONSUMIDOR</b> cancela el presente contrato después de los cinco hábiles que prevé la claúsula quinta <b>EL CONSUMIDOR</b> deberá indemnizar a<b>EL PRESTADOR DEL SERVICIO</b> por los gastos comprobables hasta por un 20% del costo total de la operación que hubiese realizado en ejecución de la prestación del servicio. </div>
            <div> <b>DÉCIMA QUINTA.-</b> Para la interpretación y cumplimiento del presente contrato las partes se someten a la competencia en la vía administrativa a la Procuraduría Federal <b>DEL CONSUMIDOR</b> ya las leyes y jurisdicción de los tribunales competentes del fuero común. </div>
            <div> Leído que fue el presente documento y enteradas las partes de su alcance y contenido legal, lo suscriben en la Ciudad de MÉXICO a los { dayjs( new Date() ).format("DD") } días del mes de { dayjs( new Date() ).locale("es").format("MMMM") } del { dayjs( new Date() ).format("YYYY") } </div>
            <br/><br/><br/><br/>

            <Container>
              <Row>
                <Col md={ 2 } sm={ 1 } xs={ 1 }></Col>
                <Col md={ 3 } sm={ 4 } xs={ 4 }>
                  <div align="center">
                    <div> <hr/> </div>
                    <div> EL PRESTADOR DEL SERVICIO </div>
                  </div>
                </Col>
                <Col md={ 2 } sm={ 2 } xs={ 2 }></Col>
                <Col md={ 3 } sm={ 4 } xs={ 4 }>
                  <div align="center">
                    <div> <hr/> </div>
                    <div> EL CONSUMIDOR </div>
                  </div>
                </Col>
                <Col md={ 2 } sm={ 1 } xs={ 1 }></Col>
              </Row>
            </Container>
          </div>
        </div>
        <br/><br/>

        <div align="right">
          <span className="single-btn" onClick={ () => setShowContract(false) } > CANCELAR </span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="grl-btn" onClick={ handlePrint } > IMPRIMIR </span>
        </div>
      </div>
    </>
  );
}
