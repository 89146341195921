import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import { isMobile } from "react-device-detect";

export default function Day({ day, rowIdx }) {
  const [dayEvents, setDayEvents] = useState([]);
  const {
    setDaySelected,
    setShowEventModal,
    filteredEvents,
    setSelectedEvent,
    selectedEvent,
    setShowEventForm,
    setDateSelected,
    setDayEventsMobile
  } = useContext(GlobalContext);

  useEffect(() => {
    const events = filteredEvents.filter(
      (evt) =>
        dayjs(evt.eventDate).format("DD-MM-YY") === day.format("DD-MM-YY")
    );
    setDayEvents(events);
  }, [filteredEvents, day]);

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-blue-600 text-white rounded-full w-7"
      : "";
  }

  return (
    <div className="border border-gray-200 flex flex-col">
      <header className="flex flex-col items-center">
        {rowIdx === 0 && (
          <p className="text-sm mt-1">
            {day.locale("es").format("ddd").toUpperCase()}
          </p>
        )}
        <p
          className={`text-sm p-1 my-1 text-center  ${getCurrentDayClass()}`}
        >
          {day.format("DD")}
        </p>
      </header>
      <div
        className="flex-1 cursor-pointer"
        onClick={() => {
            setShowEventModal(true)
            setDaySelected(day)
            setDateSelected(day)
            
            if( isMobile ) setDayEventsMobile( dayEvents )
        }}
      >
        { !isMobile ? 
          <div>
            {dayEvents.map((evt, idx) => (
              <div
                key={idx}
                onClick={() => { 
                  if( !isMobile ) {
                    setSelectedEvent(evt)
                    setDateSelected(day)
                  }
                }}
                className={`bg-${evt.label}-200 p-1 mr-3 text-gray-600 text-sm rounded mb-1 truncate`}
              >
                {evt.eventName}
              </div>
            ))}
          </div> :
          <div>
            { dayEvents.length > 0 &&
              <div className="circle-ind" />
            }
          </div>
        }

        
      </div>
    </div>
  );
}
