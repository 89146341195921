
import { useState, useEffect } from 'react';
import axios from 'axios'
import base64 from 'base-64'
import { isMobile } from 'react-device-detect';


import Menu from './Menu'
import SectionTitle from './SectionTitle'
import Events from './Events'
import Payments from './Payments'
import Charges from './Charges'
import Packages from './Packages'
import Reports from './Reports'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Offcanvas from 'react-bootstrap/Offcanvas'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Loading.css';

import logo from './img/logo_b.png'
import splashUs from './img/welcome.png'


function App() {
  const [ section, setSection ] = useState( 'events' )
  const [ globalLoading, setGlobalLoading ] = useState( false )

  const [ sign, setSign ] = useState(false)
  const [ email, setEmail ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ searchText, setSearchText ] = useState("Iniciar sesión")
  
  const [ isActive, setIsActive ] = useState(false)
  const [ mailBody, setMailBody ] = useState("");
  const [ mailTitle, setMailTitle ] = useState("");
  const [ showMesage, setShowMesage ] = useState(false);

  useEffect(() => {
    if(sessionStorage.getItem("splash_session_auth"))
      setSign(true)
  }, []);

  const encode64 = (input) => {
    return base64.encode(input);
  };

  const getLogin = async (e) => {
    e.preventDefault();
    var userPass = 'Basic ' + encode64(email + ":" + password);
    console.log("setting login", userPass)

    var form = document.getElementById('loginForm');
		var formData = new FormData(form);
    formData.append("inputPassword", userPass);
    
    /*axios.post('http://192.169.176.97/rest/api/events/logincms/', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
    })*/
    axios({
      method: 'post',
      url: 'http://192.169.176.97/rest/api/events/logincms/',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    }).then(result => {
      const json = result.data
      console.log("json result", json)

      if( json.loginName != null && ( json.rolId == 0 || json.rolId == 1 ) ) {
        sessionStorage.setItem('splash_session_auth', json.loginName)
        sessionStorage.setItem('splash_name', json.name)
        sessionStorage.setItem('splash_rol', json.rolId)

        setSign(true)
        setIsActive(false)
        setSearchText("Iniciar sesión")
      } else {
        setIsActive(false)
        setShowMesage(true)
        setSearchText("Iniciar sesión")

        setMailTitle("Error")
        setMailBody('El usuario o contraseña son incorrectos. Favor de verificar los datos y volver a intentarlo.')
      }

      
    })
    .catch(error => {
      setIsActive(false)
      setShowMesage(true)
      setSearchText("Iniciar sesión")

      setMailTitle("Error")
      setMailBody('Ocurrio un error al intentar accesar. Favor de intentarlo más tarde.')
    })
  }

  const logOut = () => {
    sessionStorage.removeItem('splash_session_auth')
    sessionStorage.removeItem('splash_name')
    sessionStorage.removeItem('splash_rol')
    setSign(false)
    window.location.reload();
  }
  
  const handleSelectSection = sect => {
    setSection(current => sect);

    console.log('argument from Child: ', sect);
  };

  return (
    <div>
      <div className={ globalLoading ? 'lmask' : 'hide' }></div>

      <Modal show={ showMesage }>
        <Modal.Body>
          <Modal.Title>{ mailTitle }</Modal.Title>
          { mailBody }
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={ () => setShowMesage(false) } >Aceptar</Button>
        </Modal.Footer>
      </Modal>

      { !sign &&
        <div className='login-container'>
       
          <Container style={{ maxWidth: '100%' }}>
            <Row className='align-items-center'>
              <Col md={6}>
                <div align="center">
                  <img src={ splashUs } className='login-img' />
                </div>
              </Col>
              <Col md={6} align="center" className='login-form-cont'>
                <div style={{ maxWidth: "400px" }}>
                  <br/>
                  <br/>
                  <img src={logo} className="logo-login" />
                  <div className="title">
                    Administrador de Eventos
                  </div>
                  <Form style={{ padding: "0 25px" }} id="loginForm" onSubmit={ getLogin }>
                    <Form.Group>
                      <Form.Control required type="text" placeholder="Usuario" value={email} onChange={ (event) => setEmail(event.target.value) }/>
                    </Form.Group>
                    <br/>

                    <Form.Group>
                      <Form.Control required type="password" placeholder="Contraseña" value={password} onChange={ (event) => setPassword(event.target.value) }/>
                    </Form.Group>

                    <br/>
                    <div align="center">
                      <button className='grl-btn' type='submit' disabled={ isActive }>
                        <Spinner
                          className={isActive ? '' : 'hide'}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        { searchText }
                      </button>
                    </div>
                    <br/>
                    <br/>
                    <div className='legal'>
                      ¿Tienes alguna duda? Por favor comunicate a este correo electrónico test@test.com y dinos en que podemos ayudarte
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      }

      { sign &&
        <Container className='main-container'>
          <Row>

            { !isMobile ? 
              <Col className='col-menu-left'>
                <div className="menu-left">
                  <Menu handleSelectSection={ handleSelectSection } section={ section }/>
                </div>
              </Col> : 
              <Container>
                <Navbar expand={'sm'} className="bg-body-tertiary mb-3">
                  <Container fluid>
                    <Navbar.Brand href="#"> <img src={ logo } style={{ height: '80px' }} /> </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'sm'}`} />
                    <Navbar.Collapse className="justify-content-end">
                      <div>
                        <Menu handleSelectSection={ handleSelectSection } section={ section }/>
                      </div>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              </Container>
            }

            <Col className="menu-right">

              <nav className="blur">
                <Container>
                  <Row>
                    <Col>
                      <SectionTitle section={ section } logOut={ logOut } handleSelectSection={ handleSelectSection } />
                    </Col>
                  </Row>
                </Container>
              </nav>
              <br/>

              <Container>
                <Row>
                  <Col>
                    { section == 'events' &&
                      <Events setGlobalLoading={ setGlobalLoading }/>
                    }
                    { section == 'payments' &&
                      <Payments setGlobalLoading={ setGlobalLoading }/>
                    }
                    { section == 'charges' &&
                      <Charges setGlobalLoading={ setGlobalLoading }/>
                    }
                    { section == 'packages' &&
                      <Packages setGlobalLoading={ setGlobalLoading }/>
                    }
                    { section == 'reports' &&
                      <Reports setGlobalLoading={ setGlobalLoading }/>
                    }
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      }
    </div>
  );
}

export default App;
