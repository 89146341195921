import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { useReactToPrint } from 'react-to-print';
import { Chart } from "react-google-charts";

import PaymentForm from "./PaymentForm";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'

import './Print.css';
import { Printer, Wallet2, X } from "react-bootstrap-icons";

import logo from './img/logo_b.png'

const PACKAGES_API = 'http://192.169.176.97/rest/api/paquetes/packages'
const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const DATE_FORMAT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', day: '2-digit', hour: "numeric", minute: "numeric", hour12: false, timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })
const DATE_FORMAT_SHORT = new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

const HOURS = [
    { value: '10', label: '10:00 a 15:00 hrs' },
    { value: '16', label: '16:00 a 21:00 hrs' },
    { value: '12', label: '12:00 a 17:00 hrs' },
    { value: '13', label: '13:00 a 18:00 hrs' },
    { value: '14', label: '14:00 a 19:00 hrs' },
    { value: '15', label: '15:00 a 20:00 hrs' },
  ]

function Print({ payment, event, balancePrint }) {
  const [ packages, setPackages ] = useState([])
  const [ packageName, setPackageName ] = useState([])
  const [ people, setPeople ] = useState(0)
  const [ hours, setHours ] = useState('')
  const componentRef = useRef();

  useEffect(() => {
    console.log("json", event)
    getPackages()
    
    let date  = new Date(event.eventDate)
    let hourP = date.getHours()
    setHours( HOURS.find( (e) => e.value == hourP ) )
  }, []);

  useEffect(() => {
    console.log('ver paquetes', packages)
    packages.map( pack => {
      pack.packagePeople.map( item => {
        if( item.packagePeopleId == event.packagePeopleId) {
            console.log('ver people', item.people)
           setPackageName( pack.packageName )
           setPeople( item.people.quantity )
        }
      })
    })

  }, [packages]);

  const getPackages = () => {
    axios({
      method: 'get',
      url: PACKAGES_API,
      headers: { 'content-type': 'application/json' },
      //headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(result => {
      console.log("packages", result.data)
      setPackages(result.data)
    })
    .catch(error => {
      console.log("error", error.message)
    })
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>

      <div className="toolbar">
          <button onClick={ handlePrint }> <Printer />&nbsp;&nbsp;<span>Imprimir</span> </button>
      </div>

      <div ref={componentRef} style={{ position: 'relative' }}>
        <div className="container_p">
            <img className="water-mark" src={ logo } />
            <div className="header">
                <div className="h-title">
                    Splash Fun Clavería
                </div>
                <div className="h-address">
                    Calle Egipto 185, Planta baja, Col. Clavería, 02080, Alcandía Azacapotzalco, CDMX
                </div>
                <div className="h-address">
                    Cel.: 56 1176 6059
                </div>
                <div className="h-address">
                    Tel.: 55 6273 6484
                </div>
                <div className="h-info">
                    <div className="hi-date">
                        <strong>Fecha:</strong> { DATE_FORMAT.format((new Date()).getTime()) }
                    </div>
                    <div className="hi-page">
                        <strong>Hoja:</strong> 1
                    </div>
                </div>
                <div className="h-logo">
                    <img src={ logo } />
                </div>
            </div>
            
            <br/>
            <div className="g-title">
                Comprobante de Pago
            </div>
            <br/>

            <div className="content">
                <div className="u-info">
                    <div className="ui-panel">
                        { payment.paymentStatusId == 2 && 
                            <div className="u-label">
                                <strong>Nombre</strong>: { event.clientName }
                            </div>
                        }
                        { payment.paymentStatusId == 2 && 
                            <div className="u-label">
                                <strong>Evento</strong>: { event.eventName }
                            </div>
                        }
                        { payment.paymentStatusId == 2 && 
                            <>
                                <div className="u-label">
                                    <strong>Paquete</strong>: { packageName }
                                </div>
                                <div className="u-label">
                                    <strong>Personas</strong>: { people }
                                </div>
                            </>
                        }
                        <div className="u-label">
                            <strong>Fecha del evento</strong>: { DATE_FORMAT_SHORT.format((new Date( event.eventDate )).getTime()) }
                        </div>
                        <div className="u-label">
                            <strong>Hora del evento</strong>: { hours.label }
                        </div>
                    </div>
                    <div className="ui-panel">
                        <div className="u-label">
                            <strong>Fecha de Pago</strong>: { DATE_FORMAT_SHORT.format((new Date( payment.dateApplication )).getTime()) }
                        </div>
                        <div className="u-label">
                            <strong>Id de Pago</strong>: { 'SPLC0' + payment.paymentId }
                        </div>
                        { payment.paymentStatusId == 2 && 
                            <div className="u-label">
                                <strong>Número de pago</strong>: { payment.payNumber }
                            </div>
                        }
                        <div className="u-label">
                            <strong>Estado del pago</strong>: { payment.paymentStatus.description }
                        </div>
                        <div className="u-label">
                            <strong>Tipo de Pago</strong>: { payment.paymentType.name }
                        </div>
                    </div>
                </div>
                <div className="u-payments">
                    <div className="ui-panel">
                        <div className="u-label">
                            <strong>Concepto</strong>:
                            <div>{ payment.description }</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="u-amount">                
                <div style={{ fontSize: '18px' }}><strong>Monto Pagado</strong>: { CURRENCY_FORMAT.format( payment.paymentAmount ) }</div>
                { payment.paymentStatusId == 2 && 
                    <>
                        <br/>
                        <div style={{ fontSize: '12px' }}>
                            <Container>
                                <Row style={{ paddingTop: '5px' }}>
                                    <Col><strong>Costo del paquete</strong>:</Col>
                                    <Col md={ 4 }>{ CURRENCY_FORMAT.format( event.packageCost ) }</Col>
                                </Row>
                                { event.extrasCost > 0 && 
                                    <Row style={{ paddingTop: '5px' }}>
                                        <Col><strong>Costo de conceptos adicionales</strong>:</Col>
                                        <Col md={ 4 }>{ CURRENCY_FORMAT.format( event.extrasCost ) }</Col>
                                    </Row>
                                }
                                { event.putIva && 
                                    <Row style={{ paddingTop: '5px' }}>
                                        <Col><strong>IVA</strong>:</Col>
                                        <Col md={ 4 }>{ CURRENCY_FORMAT.format( event.iva ) }</Col>
                                    </Row>
                                }
                                <Row style={{ paddingTop: '5px' }}>
                                    <Col><strong>Costo total del evento</strong>:</Col>
                                    <Col md={ 4 }>{ CURRENCY_FORMAT.format( event.cost ) }</Col>
                                </Row>
                                <Row style={{ paddingTop: '5px' }}>
                                    <Col><strong>Saldo Anterior</strong>:</Col>
                                    <Col md={ 4 }>{ CURRENCY_FORMAT.format( event.cost - balancePrint ) }</Col>
                                </Row>
                                {/* <Row style={{ paddingTop: '5px' }}>
                                    <Col><strong>Su pago</strong>:</Col>
                                    <Col md={ 4 }>{ CURRENCY_FORMAT.format( payment.paymentAmount ) }</Col>
                                </Row> */}
                                <Row style={{ paddingTop: '5px' }}>
                                    <Col><strong>Saldo nuevo</strong>:</Col>
                                    <Col md={ 4 }>{ CURRENCY_FORMAT.format( event.cost - balancePrint - payment.paymentAmount ) }</Col>
                                </Row>
                            </Container>
                        </div>
                    </>
                }
            </div>
            {/*<div className="u-footer">
                <div className="uf-esign">
                    <strong>Cadena Digital</strong>:<br/>
                    blablablablablabla
                </div>
            </div>*/}
            <div style={{ position: "absolute", right: '10px', bottom: "10px", fontSize: '12px' }} >* Este recibo no es un comprobante fiscal.</div>
        </div>
      </div>

    </>
  );
}

export default Print;
